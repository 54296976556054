<template>
  <header id="top-menu">
    <!-- logo -->
    <div class="header-logo">
      <RouterLink to="/">
        <img src="@/assets/img/logo.svg" alt="Follaw Logo" />
      </RouterLink>
    </div>

    <div class="filters-wrapper" data-product-tour="step-2">
      <template v-if="!isSearchbarExtended">
        <div v-if="userSettingsStore.hasPermission('BASIC')">
          <SelectFilter
            v-model="userStore.selectedDashboard"
            name="Dashboards"
            :items="userStore.dashboards"
            item-label="name"
            item-value="_id"
            mode="topbar"
            @update:model-value="updateDashboard"
          >
          </SelectFilter>
        </div>
        <div v-else class="dashboard-select" @click="clickBlockedDashboards">
          <div class="fake-dashboard-select">Tout le contenu</div>
          <PermissionLevelIcon
            v-if="!userSettingsStore.hasPermission('BASIC')"
            class="permission-indicator"
            permission-level="BASIC"
          />
          <FreemiumUpgradePopup
            ref="dashboardUpgradePopup"
            :closable="true"
            title="Veilles personnalisées"
          >
            <p class="popup-p">
              Ce contenu est réservé aux abonnements payants.
            </p>
            <p class="popup-p">
              Vous ne trouvez pas votre bonheur dans notre moteur de recherche ?
              Un consultant est à votre disposition pour mettre en place une
              veille sur mesure, adaptée à vos enjeux spécifiques et, si
              nécessaire, une veille réputationnelle. Nous configurons pour vous
              une veille basée sur des mots-clés précis et pertinents afin de
              garantir des résultats efficaces et pertinents pour vos besoins et
              ceux de votre organisation.
            </p>
          </FreemiumUpgradePopup>
        </div>
      </template>
      <div class="search-wrapper">
        <button
          v-if="isSearchbarExtended"
          class="round-btn shrink-searchbar"
          @click="isSearchbarExtended = false"
        >
          <i class="fa-solid fa-arrow-left"></i>
        </button>
        <SearchFieldTopBar
          v-model="userStore.searchBoxCurrentValue"
          v-on-click-outside="() => (isSearchbarExtended = false)"
          class="search"
          :disabled="isSearchDisabled"
          @click-search="search"
          @focus="isSearchbarExtended = true"
          @update:model-value="() => (isSearchbarExtended = true)"
        ></SearchFieldTopBar>
      </div>
      <div
        v-if="!isSearchbarExtended"
        v-on-click-outside="() => (expandResponsive = false)"
        class="responsive-wrapper"
      >
        <div class="responsive-btn">
          <button
            class="more-btn"
            :class="{ 'btn-active': expandResponsive }"
            aria-label="Afficher plus de filtres"
            @click="() => (expandResponsive = !expandResponsive)"
          >
            <div
              v-if="hasActiveFiltersBubble"
              class="responsive-active-badge"
            ></div>
            <i v-if="expandResponsive" class="fa fa-chevron-up"></i>
            <i v-else class="fa fa-chevron-down"></i>
          </button>
        </div>
        <div class="responsive-overflow">
          <SelectInterval
            :key="
              userStore.selectedInterval.selected_time_range +
              userStore.selectedInterval.selected_from_date +
              userStore.selectedInterval.selected_to_date
            "
            :interval-preset="userStore.selectedInterval.selected_time_range"
            :interval-start="userStore.selectedInterval.selected_from_date"
            :interval-end="userStore.selectedInterval.selected_to_date"
            :disabled="isSelectIntervalDisabled"
            @set-time-range="setTimeRange"
          ></SelectInterval>
          <SelectDiscover
            :thematics="userStore.thematics"
            :active-thematics="selectedThematics"
            :active-subthematics="selectedSubthematics"
            @add-thematic="addSelectedFilter('thematics', $event)"
            @add-subthematic="addSelectedFilter('subthematics', $event)"
            @remove-thematic="removeSelectedFilter('thematics', $event)"
            @remove-subthematic="removeSelectedFilter('subthematics', $event)"
            @reset-all="resetDiscover"
          />
        </div>
      </div>
    </div>
    <div class="user-infos">
      <PresetsMenu v-if="userSettingsStore.hasPermission('BASIC')" />
      <div v-else class="presets-wrapper" data-product-tour="step-7">
        <RouterLink
          to="/filters-presets"
          class="round-btn"
          aria-label="Vers les préselections de filtres"
        >
          <i class="fa-solid fa-bookmark"></i>
        </RouterLink>
        <PermissionLevelIcon
          class="permission-indicator"
          permission-level="BASIC"
        />
      </div>
      <div class="alerts-wrapper" data-product-tour="step-7">
        <RouterLink
          to="/alerting/alerts"
          class="round-btn"
          aria-label="Vers les alertes"
        >
          <i class="fa-solid fa-bell"></i>
        </RouterLink>
        <PermissionLevelIcon
          v-if="!userSettingsStore.hasPermission('BASIC')"
          class="permission-indicator"
          permission-level="BASIC"
        />
      </div>
      <UserMenu />
    </div>
  </header>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { computed, ref } from "vue";
import { vOnClickOutside } from "@vueuse/components";

import UserMenu from "@/components/general/molecules/UserMenu.vue";
import PresetsMenu from "@/components/general/molecules/PresetsMenu.vue";
import SelectFilter from "@/components/reusable/atoms/SelectFilter.vue";
import SearchFieldTopBar from "@/components/topbar/atoms/SearchFieldTopBar.vue";
import SelectInterval from "../molecules/SelectInterval.vue";
import SelectDiscover from "@/components/topbar/organisms/SelectDiscover.vue";
import PermissionLevelIcon from "../atoms/PermissionLevelIcon.vue";
import FreemiumUpgradePopup from "@/components/reusable/atoms/FreemiumUpgradePopup.vue";

import { useUserStore } from "@/stores/user";
import { useUserSettingsStore } from "@/stores/user-settings";

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const userSettingsStore = useUserSettingsStore();

const expandResponsive = ref(false);
const isSearchbarExtended = ref(false);
const dashboardUpgradePopup = ref(null);

const updateDashboard = () => {
  router.push({
    query: { ...route.query, dashboard: userStore.selectedDashboard },
  });
  userStore.updateFilters({
    selected_dashboard: userStore.selectedDashboard,
  });
};

const search = () => {
  isSearchbarExtended.value = false;
  router.push({
    query: {
      ...route.query,
      search: userStore.searchBoxCurrentValue.length
        ? userStore.searchBoxCurrentValue
        : undefined,
    },
  });
};

// show that active filters are hidden by the responsive toggle
const hasActiveFiltersBubble = computed(
  () =>
    userStore.sharedFilters.selected_thematics.length +
      userStore.sharedFilters.selected_subthematics.length >
    0
);

// Theses selected* objects are built for the needs of using the id and the name
// in order to display them on the selected filters list
const selectedThematics = computed(() =>
  userStore.sharedFilters.selected_thematics.map((themId) =>
    userStore.thematics.find((t) => t._id === themId)
  )
);

const allSubthematics = computed(() => {
  return userStore.thematics.reduce((acc, them) => {
    return [...acc, ...them.subthematics];
  }, []);
});

const selectedSubthematics = computed(() =>
  userStore.sharedFilters.selected_subthematics.map((subThemId) =>
    allSubthematics.value.find((t) => t._id === subThemId)
  )
);

function getDiscoverQuery() {
  const { selected_thematics, selected_subthematics } = userStore.sharedFilters;

  return {
    thematics:
      selected_thematics.length > 0 ? selected_thematics.toString() : undefined,
    subthematics:
      selected_subthematics.length > 0
        ? selected_subthematics.toString()
        : undefined,
  };
}

function addSelectedFilter(filter, event) {
  const newId = event.item._id;
  const filterName = "selected_" + filter;
  if (!userStore.sharedFilters[filterName].includes(newId)) {
    userStore.sharedFilters[filterName].push(newId);
    router.push({
      query: {
        ...route.query,
        ...getDiscoverQuery(),
      },
    });
  }
}

function removeSelectedFilter(filter, event) {
  const idToRemove = event.item;
  const filterName = "selected_" + filter;
  userStore.sharedFilters[filterName] = userStore.sharedFilters[
    filterName
  ].filter((id) => id !== idToRemove);
  router.push({
    query: {
      ...route.query,
      ...getDiscoverQuery(),
    },
  });
}

function resetDiscover() {
  userStore.sharedFilters.thematics = [];
  userStore.sharedFilters.subthematics = [];
  router.push({
    query: {
      ...route.query,
      thematics: undefined,
      subthematics: undefined,
    },
  });
}

function clickBlockedDashboards() {
  dashboardUpgradePopup.value.open();
}

const setTimeRange = (e) => {
  userStore.selectedInterval.selected_time_range = e.interval;
  userStore.selectedInterval.selected_from_date = e.start;
  userStore.selectedInterval.selected_to_date = e.end;
  if (
    userStore.selectedInterval.selected_time_range !== "free" ||
    (userStore.selectedInterval.selected_from_date &&
      userStore.selectedInterval.selected_to_date)
  ) {
    userStore.updateFilters(userStore.selectedInterval);
  }
};

const isSelectIntervalDisabled = computed(() => {
  return (
    route.name === "Agenda" ||
    route.path.includes("/alerts") ||
    route.path.includes("/legislatives")
  );
});

const isSearchDisabled = computed(() => {
  return false; // No page where search is disabled for now
});
</script>

<style lang="scss" scoped>
.popup-p {
  margin: 0;
}

#top-menu {
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 40px #06003121;
  width: 100%;
  padding: 10px 28px;
  background-color: var(--clr-n8);
  color: white;
  z-index: 10;

  .header-logo {
    min-width: 227px;
    img {
      width: 140px;
    }
  }

  .filters-wrapper {
    display: flex;
    gap: 4px 10px;
    width: 100%;
  }

  .more-btn {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    background-color: var(--clr-p8);

    &:hover,
    &.btn-active {
      background-color: var(--clr-p8);
    }

    .responsive-active-badge {
      position: absolute;
      top: -4px;
      right: -4px;

      height: 18px;
      width: 18px;

      border: 4px var(--clr-p9) solid;
      border-radius: 50%;

      background-color: var(--clr-p2);
    }
  }

  .round-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: #fff 2px solid;
    border-radius: 50%;
    color: white;

    &.shrink-searchbar {
      border: none;
    }

    &:hover,
    &:focus {
      background-color: var(--clr-p5);
    }
    &:active {
      background-color: var(--clr-n8);
    }
  }

  .fake-dashboard-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 175px;
    height: 30px;
    border-radius: 15px;
    padding: 4px 15px;
    cursor: pointer;
    background-color: var(--clr-p8);
  }

  .dashboard-select {
    position: relative;
    width: fit-content;

    :deep(.permission-indicator) {
      width: 16px;

      position: absolute;
      bottom: calc(50% - 6px);
      right: 16px;
    }
  }

  .search-wrapper {
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
  }

  .responsive-wrapper {
    position: relative;
  }

  .responsive-btn {
    display: none;
  }

  .responsive-overflow {
    display: flex;
    gap: 10px;
  }

  @media (max-width: 1360px) {
    .responsive-btn {
      background-color: var(--clr-p5);
      display: initial;
    }

    .responsive-overflow {
      display: v-bind("expandResponsive ? 'flex' : 'none'");
      justify-content: space-around;
      width: 430px;
      padding: 10px;
      background-color: var(--clr-n8);
      position: absolute;
      translate: -90% 10%;
      border-radius: 0 0 25px 25px;
    }
  }

  .user-infos {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-left: 20px;

    .alerts-wrapper,
    .presets-wrapper {
      position: relative;

      :deep(.permission-indicator) {
        position: absolute;
        bottom: -4px;
        right: -4px;
        border-radius: 50%;
        width: 16px;
        background-color: var(--clr-n8);
      }
    }
  }
}
@media (max-width: 767px) {
  #top-menu {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;

    .header-logo {
      align-self: flex-start;
    }
    .responsive-btn {
      background-color: var(--clr-p5);
      display: initial;
      margin: 0 auto;
    }
    .responsive-overflow {
      width: 80%;
      position: absolute;
      left: 50%;
      top: -5px;
      transform: translate(-50%, 0);
      translate: 0 0;
      border-radius: 0 0 25px 25px;
    }
    .filters-wrapper {
      gap: 10px 10px;
      flex-direction: column;
      .select-interval > :deep(.select-box) {
        width: 100%;
      }
    }

    .user-infos {
      position: absolute;
      top: 20px;
      right: 15px;
      gap: 18px;
    }

    .search-wrapper,
    .responsive-wrapper {
      width: 100%;
    }
  }

  .responsive-overflow {
    flex-direction: column;
    width: 100%;
    translate: 0 0;
    align-items: center;
  }
  .responsive-btn,
  .more-btn {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  #top-menu {
    .responsive-overflow {
      width: fit-content;
    }
  }
}
</style>
