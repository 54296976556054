<template>
  <AbstractSocialCard
    :id="post._id"
    :author="post.author"
    :social-icon="{
      name: 'fa-square-instagram',
      color: 'var(--clr-instagram)',
    }"
    :handle="handleInstagram"
    :reposts="SINumberFormatter(post.reposts)"
    :likes="SINumberFormatter(post.like)"
    :comments="SINumberFormatter(post.comment)"
    :created-at="post.created_at"
    :last-update="post.last_update"
    :source-url="post.permalink"
    :is-overview="isOverview"
  >
    <template #content>
      <AbstractSocialCardContent :describe="post.text" />
    </template>

    <template v-if="post.permalink" #media>
      <div class="instagram__image">
        <iframe
          scrolling="no"
          class="instagram__image__iframe"
          :src="`${post.permalink}/embed/captioned`"
          title="Publication Instagram"
        />
      </div>
    </template>
  </AbstractSocialCard>
</template>

<script setup>
import { SINumberFormatter } from "@/utils/helper.utils.js";
import AbstractSocialCard from "@/components/social-media//abstract/AbstractSocialCard.vue";
import AbstractSocialCardContent from "@/components/social-media//abstract/AbstractSocialCardContent.vue";
import { useHandle } from "@/composables/social-media/useHandle";
import { Platforms } from "@/utils/social360-platforms.js";

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  isOverview: {
    type: Boolean,
    default: false,
  },
  tooltipActivable: {
    type: Boolean,
    default: true,
  },
});

const handleInstagram = useHandle(
  props.post.author.instagram_information?.username,
  Platforms.INSTAGRAM
);
</script>

<style lang="scss" scoped>
.instagram__image {
  display: flex;
  width: 217px;
  height: 217px;
  overflow: hidden;

  &__iframe {
    $instagram-header-height: 54px;

    position: relative;
    left: 0;
    top: -$instagram-header-height;
    height: calc(100% + $instagram-header-height);
    width: 100%;
    border: none;
  }
}
</style>
