<template>
  <LoaderFullPage v-if="store.loading" />
  <AgendaListingView v-else-if="store.activeDisplay === DISPLAY_OPTIONS.LIST" />
  <AgendaCalendarView
    v-else-if="store.activeDisplay === DISPLAY_OPTIONS.CALENDAR"
  />
</template>

<script setup>
import AgendaListingView from "@/components/agenda/organisms/AgendaListingView.vue";
import AgendaCalendarView from "@/components/agenda/organisms/AgendaCalendarView.vue";
import { DISPLAY_OPTIONS } from "@/stores/agenda";
import { useAgendaStore } from "@/stores/agenda";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";

const store = useAgendaStore();
</script>
