<template>
  <button
    v-if="plan === 'SOCIETAL' && currentPlan === 'TRIAL'"
    class="btn-primary current-plan"
    disabled
  >
    Essai en cours
  </button>
  <button
    v-else-if="plan === currentPlan"
    class="btn-primary current-plan"
    disabled
  >
    Abonnement actuel
  </button>
  <button v-else class="btn-primary get-plan" @click="onClick">
    Obtenir ce plan
  </button>
</template>

<script setup>
import { useUserSettingsStore } from "@/stores/user-settings";

const store = useUserSettingsStore();

const props = defineProps({
  plan: { type: String, required: true },
  period: { type: String, required: true },
  currentPlan: { type: String, required: true },
});

function onClick() {
  const planName =
    props.plan === "FREE" ? props.plan : props.plan + "_" + props.period;
  store.redirectToStripePricePage(planName);
}
</script>

<style scoped>
.current-plan {
  background-color: var(--clr-p3);
}
.get-plan {
  background-color: var(--clr-s5);

  &:hover,
  &:focus {
    background-color: var(--clr-s4);
  }
  &:active {
    background-color: var(--clr-s5);
  }
}
</style>
