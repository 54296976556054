<template>
  <div class="card wrapper">
    <button
      class="icon clickable-bigicon"
      aria-label="Vers les auteurs"
      @click="$emit('clickIcon')"
    >
      <i class="fa-solid fa-user-pen" />
    </button>
    <div v-if="props.isLoading">
      <hollow-dots-spinner
        :animation-duration="1000"
        color="var(--clr-service-author)"
      />
    </div>
    <div v-else class="metrics">
      <div class="primary-metric">
        <span class="value">{{ SINumberFormatter(props.authorsCount) }}</span>
        Auteurs
      </div>
      <div class="secondary-metric">
        <span class="value">{{ props.authorsPercent || 0 }}%</span> Membres du
        panel
      </div>
    </div>
  </div>
</template>

<script setup>
import { HollowDotsSpinner } from "epic-spinners";

import { SINumberFormatter } from "@/utils/helper.utils";

const props = defineProps({
  isLoading: { type: Boolean, required: true },
  authorsCount: { type: Number, default: 0 },
  authorsPercent: { type: Number, default: 0 },
});

defineEmits(["clickIcon"]);
</script>

<style scoped lang="scss">
.wrapper {
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  --context-color: var(--clr-service-author);
  .clickable-bigicon:hover {
    opacity: 0.8;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 65px;
  height: 65px;
  border-radius: 50%;

  font-size: 2rem;
  background-color: var(--context-color);
  color: white;
}

.metrics {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}

.primary-metric {
  display: flex;
  align-items: center;
  gap: 8px;
  color: black;

  font-size: 1.4rem;

  .value {
    font-size: 1.8rem;
  }
}

.secondary-metric {
  display: flex;
  align-items: center;
  gap: 8px;
  color: black;

  font-size: 1rem;
  .value {
    font-size: 1.4rem;
  }
}

.value {
  color: var(--context-color);
}
</style>
