<template>
  <div class="template">
    <RouterRadioButtons :options="menuOptions" />
    <RouterView />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { RouterView } from "vue-router";
import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";
import { useUserSettingsStore } from "@/stores/user-settings";

const store = useUserSettingsStore();

const menuOptions = computed(() =>
  [
    {
      label: "Mon compte",
      value: "/user-settings/account",
    },
    {
      label: "Abonnement et facturation",
      value: "/user-settings/subscription",
    },
    {
      label: "Préférences de notification",
      value: "/user-settings/notifications",
    },
  ].filter((option) =>
    store.user.permission_level !== "FREE"
      ? option
      : option.label !== "Préférences de notification"
  )
);
</script>

<style lang="scss" scoped>
h2 {
  margin: 0 16px;
}
</style>
