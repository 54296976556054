<template>
  <div>
    <RouterRadioButtons
      :is-blue-style="true"
      :options="props.isZoomed ? menuOptions : follawedMenuOptions"
    />
  </div>
  <div class="params-row request-and-check-all">
    <SwitchSlide
      :is-active="!store.bypassQueryActivities"
      @toggle="toggleUseRequest"
    >
      Utiliser la requête
    </SwitchSlide>
    <div class="activities-params">
      <TotalMatched
        :item-count="
          isLobbyingRoute ? store.activitiesCounts : store.publicationsCounts
        "
      />
      <div class="page-settings">
        <SelectItemsLimit
          :items-limit="selectedLobbiesActivitiesParams.size"
          @change-limit="changeLimit"
        >
          Activités
        </SelectItemsLimit>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import TotalMatched from "@/components/reusable/atoms/TotalMatched.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";
import { useLobbiesStore } from "@/stores/lobbies";

const route = useRoute();
const store = useLobbiesStore();
const { selectedFilters } = storeToRefs(store);

const props = defineProps({
  isZoomed: {
    type: Boolean,
    default: false,
  },
});

const isLobbyingRoute = ref(route.fullPath.includes("lobbying") ? true : false);
const { selectedLobbiesActivitiesParams } = storeToRefs(store);

// Request params
const toggleUseRequest = () => {
  store.bypassQueryActivities = !store.bypassQueryActivities;
  fetchLobbiesActivities();
};

// Limit
const changeLimit = (size) => {
  selectedLobbiesActivitiesParams.value.size = size;
  selectedLobbiesActivitiesParams.value.from = 0;
  fetchLobbiesActivities();
};

// Routing options depending on FollawedRadioButtons
const menuOptions = [
  {
    label: "Lobbying",
    value: `/lobbies/${store.lobbyZoom?.national_id}/activities/lobbying`,
  },
  {
    label: "Publications",
    value: `/lobbies/${store.lobbyZoom?.national_id}/activities/publications`,
  },
];

const follawedMenuOptions = [
  {
    label: "Lobbying",
    value: `/lobbies/follawed/activities/lobbying`,
  },
  {
    label: "Publications",
    value: `/lobbies/follawed/activities/publications`,
  },
];

const lobbyRouteHandlers = {
  "Lobby Activités - Lobbying": () =>
    store.readOneLobbyActivities(route.params._id),
  "Lobbies Follawed - Lobbying": () => store.readFollawedActivities(),
  "Lobby Activités - Publications": () =>
    store.readOneLobbyPublications(route.params._id),
  "Lobbies Follawed - Publications": () => store.readFollawedPublications(),
};

const fetchLobbiesActivities = async () => {
  selectedLobbiesActivitiesParams.value.from = 0;
  selectedFilters.value.from = 0;

  const handleLobbyRoute = lobbyRouteHandlers[route.name];
  if (handleLobbyRoute) await handleLobbyRoute();
};

fetchLobbiesActivities();
</script>

<style lang="scss" scoped>
.activities-params {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.request-and-check-all {
  justify-content: flex-start;
  gap: 15px;
}
.switch-slide {
  margin: 8px 0;
}
</style>
