<template>
  <div class="activities-wrapper">
    <LobbiesActivitiesParams
      v-if="route.fullPath.includes('activities')"
      :is-zoomed="!isFollawed"
    />
    <RouterView />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import LobbiesActivitiesParams from "@/components/lobbies/organisms/LobbiesActivitiesParams.vue";

const route = useRoute();
import { RouterView } from "vue-router";

const isFollawed = ref(route.fullPath.includes("follawed") ? true : false);
</script>

<style lang="scss" scoped>
.activities-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
