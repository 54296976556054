<template>
  <LoaderFullPage v-if="store.loading" />
  <NoData v-else-if="!orderedNonEmptyGroups?.length" />
  <div v-else class="columns-wrapper">
    <MappingColumn
      v-for="groupName in orderedNonEmptyGroups"
      :key="groupName"
      :column-data="politicalMap[groupName]"
      :metric="store.selectedFilters.sort_field"
      :show-group-tags="false"
    >
      <template #header>
        <GroupTag :background-color="getColorForGroup(groupName)">{{
          groupName
        }}</GroupTag>
      </template>
    </MappingColumn>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";

import MappingColumn from "@/components/social-media/stakeholders/molecules/MappingColumn.vue";
import { useStakeholdersStore } from "@/stores/social-media/stakeholders";
import { getSortedNonEmptyColumns } from "@/utils/stakeholder_mapping";

const store = useStakeholdersStore();

const { filters, politicalMap } = storeToRefs(store);

const orderedNonEmptyGroups = computed(() =>
  getSortedNonEmptyColumns(
    politicalMap.value,
    filters.value.group,
    store.selectedFilters.sort_field
  )
);

function getColorForGroup(groupName) {
  if (politicalMap.value[groupName].length === 0) {
    return "gray";
  }
  return politicalMap.value[groupName][0].group.color;
}

store.fetchPoliticalMapping();
</script>

<style scoped lang="scss">
$bodyHeaderHeight: 102px;

.columns-wrapper {
  display: flex;
  gap: 5px;

  width: 100%;
  height: calc(100vh - var(--top-menu-height) - $bodyHeaderHeight - 28px);
  overflow-x: auto;
}
</style>
