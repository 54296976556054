<template>
  <template v-if="store.items.length">
    <!-- Cards -->
    <div v-if="store.items" class="cards-wrapper">
      <PublicationCard
        v-for="post in store.items"
        :key="post._id"
        :post="post"
      />
    </div>

    <!-- Pagination -->
    <PaginationElement
      v-if="store.item_count"
      :key="`${store.item_count}${store.selectedFilters.from}${store.selectedFilters.size}`"
      :total-items="store.item_count"
      :current-page="store.selectedFilters.from"
      :items-limit="store.selectedFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <NoData v-if="!store.loading && !store.item_count" />
</template>

<script setup>
import PublicationCard from "@/components/social-media/posts/molecules/PublicationCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import { useSocialMediaStore } from "@/stores/social-media/posts";
import NoData from "@/components/reusable/atoms/NoData.vue";

const store = useSocialMediaStore();

const changeCurrentPage = async (from) => {
  store.selectedFilters.from = from;
  await store.getPublicationsList();
};
</script>

<style scoped>
.cards-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(750px, 1fr));
  gap: 8px;
}
</style>
