<template>
  <div v-if="store.isActivitiesLoading">
    <LoaderFullPage />
  </div>
  <template v-else-if="store.lobbiesActivities?.length">
    <div class="activities-wrapper" :class="{ zoomed: !isFollawed }">
      <template
        v-for="item in store.lobbiesActivities"
        :key="item._id || item.id_twitter"
      >
        <LobbyActivityCard v-if="item.type === 'lobby'" :activity="item" />
      </template>
      <div class="pagination-wrapper">
        <PaginationElement
          v-if="store.activitiesCounts"
          :key="`${store.activitiesCounts}${store.selectedLobbiesActivitiesParams.from}${store.selectedLobbiesActivitiesParams.size}`"
          :total-items="store.activitiesCounts"
          :current-page="store.selectedLobbiesActivitiesParams.from"
          :items-limit="store.selectedLobbiesActivitiesParams.size"
          @change-current-page="changeCurrentPage"
        />
      </div>
    </div>
  </template>
  <div v-else class="card">
    <EmptySection :message="emptyMessage" />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import LobbyActivityCard from "@/components/lobbies/atoms/LobbyActivityCard.vue";
import EmptySection from "@/components/reusable/molecules/EmptySection.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import { useLobbiesStore } from "@/stores/lobbies";

const store = useLobbiesStore();
const route = useRoute();

const isFollawed = ref(route.fullPath.includes("follawed") ? true : false);
const emptyMessage = computed(() => {
  return isFollawed.value
    ? "Vos organisations follawed n'ont pas d'activité."
    : "Cette organisation n'a pas d'activité.";
});

const changeCurrentPage = (from) => {
  store.selectedLobbiesActivitiesParams.from = from;
  isFollawed.value
    ? store.readFollawedActivities()
    : store.readOneLobbyActivities(route.params._id);
};
</script>
<style lang="scss" scoped>
.activities-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  &.zoomed {
    display: flex;
    flex-direction: column;
  }
}
.pagination-wrapper {
  grid-column: span 2;
}
</style>
