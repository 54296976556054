<template>
  <div class="primary_tab" :class="{ active: isCurrentTab(tab, route) }">
    <i
      :class="
        isCurrentTab(tab, route)
          ? `fa-solid ${tab.icon_class}`
          : `fa-light ${tab.icon_class}`
      "
    ></i>
    <span v-show="!responsiveMenu" class="tab_name">{{ tab.name }}</span>
    <span
      v-if="
        tab.meta?.permission_level &&
        !userSettingsStore.hasPermission(tab.meta.permission_level)
      "
    >
      <PermissionLevelIcon
        class="permission-level-icon"
        :permission-level="tab.meta.permission_level"
      />
    </span>
    <span v-else-if="tab.secondary_tabs && !responsiveMenu" class="chevron">
      <i
        :class="`fa fa-chevron-${expandedTab === tab.name ? 'up' : 'down'}`"
      ></i>
    </span>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { isCurrentTab } from "@/utils/navigation.js";
import PermissionLevelIcon from "./PermissionLevelIcon.vue";
import { useUserSettingsStore } from "@/stores/user-settings";

const userSettingsStore = useUserSettingsStore();

const route = useRoute();
defineProps({
  expandedTab: {
    type: String,
    default: null,
  },
  tab: {
    type: Object,
    required: true,
  },
  responsiveMenu: {
    type: Boolean,
  },
});
</script>

<style scoped lang="scss">
.primary_tab {
  position: relative;

  padding: 2px 5px;
  font-weight: 300;
  font-size: 0.95em;
  cursor: pointer;
  white-space: nowrap;

  color: white;

  i {
    font-size: 16px;
    margin: 10px 10px 10px 15px;
  }

  &.active {
    background-color: var(--clr-p8);
    font-weight: 600;
  }

  &:hover {
    background-color: var(--clr-n8);
  }
}

:deep(.permission-level-icon) {
  width: 20px;
  height: 20px;

  display: inline;
  position: absolute;
  right: 8px;
  top: 7px;
}

.chevron {
  display: inline;
  position: absolute;
  right: 0;

  i {
    font-size: 10px;
  }
}
</style>
