<template>
  <iframe
    src="https://www.senat.fr/enseance/textes/accueil.html"
    frameborder="0"
    title="Vue de la page En Séance du sénat"
  />
</template>

<style scoped>
iframe {
  height: calc(100vh - 142px);
}
</style>
