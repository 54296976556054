<template>
  <!-- petitions details -->
  <div class="card">
    <div class="header">
      <p>
        Date de création :
        <DataOrMissingData
          :data="
            store.currentPetition.published_at
              ? getDateShortFormat(store.currentPetition.published_at)
              : null
          "
        />
      </p>
      <p>
        Date d'échéance :
        <DataOrMissingData
          :data="
            store.currentPetition.deadline
              ? getDateShortFormat(store.currentPetition.deadline)
              : null
          "
        />
      </p>
      <SourceLink
        :href="store.currentPetition.url"
        :label="store.currentPetition.origin"
      />
    </div>

    <div class="body">
      <div class="title-and-follaw">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h5 v-html="store.currentPetition.title" />
        <FollawButton
          :element-id="store.currentPetition._id"
          :feature="FOLLAWED_FEATURES.PETITIONS"
        />
      </div>
      <p v-if="store.currentPetition.commission">
        {{ store.currentPetition.commission }}
      </p>
      <p class="author">
        <span class="title"><i class="fa-solid fa-user-pen"></i> Auteur :</span>
        {{ store.currentPetition.author }}
      </p>
    </div>
  </div>

  <!-- Courbe de signatures -->
  <div class="card">
    <div class="header graph">
      <h5>Courbe temporelle des signatures</h5>
      <DownloadButton @click="downloadGraphExcel()" />
    </div>
    <SignaturesChart
      :signatures-history="store.currentPetition.signatures_history"
    />
  </div>

  <div class="datas-wrapper">
    <div class="left-column">
      <!-- Nombre de signatures -->
      <div class="card">
        <div class="nbre-signatures">
          <div class="row titles">
            <div>Nombre de signatures</div>
            <div>Dernières 24h</div>
          </div>
          <div class="row">
            <p>
              <span class="title-medium-semibold">{{
                formatNumbers(store.currentPetition.signatures_number)
              }}</span
              >/<span class="title-medium-regular">{{
                formatNumbers(store.currentPetition.signatures_target)
              }}</span>
            </p>
            <p>
              <template v-if="store.currentPetition.last_24h > 0"> + </template>
              {{ formatNumbers(store.currentPetition.last_24h) }}
            </p>
          </div>
          <div class="row">
            <PetitionProgressBar :progression="getSignaturePercent" />
          </div>
        </div>
        <!-- Progression graph -->
      </div>
      <!-- Partages sur Twitter -->
      <div class="card tweets-container">
        <!-- <p class="tweets-container__title"></p> -->

        <h5>
          <i
            class="fa-brands fa-lg fa-square-x-twitter"
            :style="'color: var(--clr-twitter)'"
          />
          Partages sur X
        </h5>

        <p
          v-if="!store.currentPetition.x_authors?.length"
          class="twitter-datas-missing"
        >
          <i class="fa-solid fa-ban" /> Données absentes
        </p>
        <div v-else class="tweets">
          <!-- div needed to avoid the card taking too much space with the "flex: 1" -->
          <div
            v-for="tweet in store.currentPetition.x_authors"
            :key="tweet._id || tweet.id_twitter"
          >
            <XCard :post="tweet" />
          </div>
        </div>
      </div>
    </div>
    <!-- Details -->
    <!-- eslint-disable vue/no-v-html -->
    <div
      ref="petitionText"
      class="card typo-body-regular"
      v-html="store.currentPetition.text"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script setup>
import { computed } from "vue";
import xlsx from "json-as-xlsx";
import DataOrMissingData from "@/components/petitions/atoms/DataOrMissingData.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import DownloadButton from "@/components/reusable/atoms/DownloadButton.vue";
import SignaturesChart from "@/components/petitions/atoms/SignaturesChart.vue";
import PetitionProgressBar from "@/components/petitions/atoms/PetitionProgressBar.vue";
import XCard from "@/components/social-media/posts/molecules/XCard.vue";
import formatNumbers from "@/utils/formatNumbers";
import { getDateShortFormat } from "@/utils/formatDate";
import { usePetitionsStore } from "@/stores/petitions";
import { FOLLAWED_FEATURES } from "@/stores/user";

const store = usePetitionsStore();

const getSignaturePercent = computed(() => {
  return Math.round(
    (Number(store.currentPetition.signatures_number) /
      Number(store.currentPetition.signatures_target)) *
      100
  );
});

function downloadGraphExcel() {
  const dataAsJson = [
    {
      sheet: "Pétition",
      columns: [
        { label: "Date", value: "date" },
        { label: "Signatures", value: "signatures_number" },
      ],
      content: store.currentPetition.signatures_history,
    },
  ];

  xlsx(dataAsJson, {
    fileName: `petition_${store.currentPetition._id}_signatures`,
  });
}
</script>

<style scoped lang="scss">
p {
  margin: 0;
}

.header.graph {
  border: none;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title-and-follaw {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .author .title {
    color: var(--clr-s7);
  }
}

.datas-wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 8px;
  // Keep important 1/3 ratio for lager screens
  @media (min-width: 1400px) {
    grid-template-columns: 1fr 2.5fr;
  }

  .left-column {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 0;
    min-height: 100%;
  }
}

.nbre-signatures {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .titles {
    color: var(--clr-s7);
  }

  .row {
    display: flex;
    justify-content: space-between;
  }
}

.tweets-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  // calc is the sum of the signatures card height and the gap
  max-height: calc(100% - 84px);

  .twitter-datas-missing {
    display: flex;
    justify-content: center;
    padding: 16px;
    margin: 32px 0;
    gap: 4px;
  }

  .tweets {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
  }
}
</style>
