<template>
  <div class="preset-menu" data-product-tour="step-6">
    <button
      class="preset-menu-trigger"
      aria-label="Ouvrir le menu de préselection de filtres"
      @click="toggleMenu"
    >
      <i class="fa-solid fa-bookmark"></i>
    </button>
    <div
      v-if="menu"
      v-on-click-outside="toggleMenu"
      class="preset-menu-options"
    >
      <button class="btn-primary" @click="openCreateDialog">
        <i class="fa-solid fa-sm fa-bookmark"></i>Enregistrer ma sélection
        actuelle
      </button>
      <div class="quick-presets">
        <button
          v-for="preset in lastPresets"
          :key="preset.id"
          title="Appliquer ce preset"
          class="menu-item"
          @click="store.applyPreset(preset.id, preset.filter)"
        >
          {{ preset.name }}
        </button>
      </div>
      <RouterLink to="/filters-presets" class="toList" @click="toggleMenu">
        <i
          class="fa-duotone fa-bookmark"
          style="
            --fa-primary-color: var(--clr-p5);
            --fa-secondary-color: var(--clr-p3);
          "
        ></i>
        Voir tous les enregistrements
      </RouterLink>
    </div>
  </div>

  <PopupDialog
    ref="createModal"
    title="Sauvegarder ma sélection de filtres"
    @close="closeDialog"
  >
    <template #body>
      <AddEditPresetForm
        v-if="showForm"
        ref="createForm"
        @submit="createPreset($event)"
      />
    </template>
    <template #footer>
      <button class="btn-primary" @click="submitCreate">Sauvegarder</button>
    </template>
  </PopupDialog>
</template>

<script setup>
import dayjs from "dayjs";
import { ref, computed } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import PopupDialog from "@/components/reusable/atoms/PopupDialog.vue";
import AddEditPresetForm from "@/components/general/molecules/AddEditPresetForm.vue";
import { useFilterPresetsStore } from "@/stores/filter-presets";

const store = useFilterPresetsStore();

const menu = ref(false);

const toggleMenu = () => {
  menu.value = !menu.value;
};

const lastPresets = computed(() =>
  [...store.presets]
    .sort((a, b) => dayjs(b.updated_at).diff(dayjs(a.updated_at)))
    .slice(0, 5)
);

// Modal
const showForm = ref(false);
const createModal = ref();
const openCreateDialog = () => {
  showForm.value = true;
  createModal.value?.open();
};
const closeDialog = (modal) => {
  modal?.close();
  showForm.value = false;
};
const createForm = ref();
const submitCreate = () => {
  createForm.value?.form.requestSubmit();
};
const createPreset = (formData) => {
  if (createForm.value?.validate()) {
    store.createPreset(formData);
    closeDialog(createModal.value);
  }
};

store.readPresets();
</script>

<style lang="scss" scoped>
.preset-menu {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;

  .preset-menu-trigger {
    border: #fff 2px solid;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: white;
    margin: 0 8px;

    i {
      font-size: 0.938em;
    }

    &:hover,
    &:focus {
      background-color: var(--clr-p5);
    }
    &:active {
      background-color: var(--clr-n8);
    }
  }

  .preset-menu-options {
    position: absolute;
    top: 3.5em;
    right: -1em;
    width: 310px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 5px;
    padding: 8px;
    background-color: white;
    z-index: 1000;
    box-shadow: 0px 6px 40px 0px rgba(0, 0, 0, 0.16);

    &:before {
      content: "";
      position: absolute;
      border: 0.75em solid;
      border-color: transparent transparent #fff transparent;
      right: 1.7em;
      top: -1.5rem;
    }

    .btn-primary i {
      margin-right: 10px;
    }

    .quick-presets {
      display: flex;
      flex-direction: column;
      width: 100%;

      .menu-item {
        border-radius: 4px;
        width: 100%;
        padding: 4px 4px 4px 8px;
        line-height: var(--lh-normal);
        text-align: left;
        color: var(--clr-n8);
        cursor: pointer;

        &:hover {
          background-color: var(--clr-p1);
        }

        &:active {
          background-color: var(--clr-p2);
          box-shadow: var(--shd-button-inner);
        }

        &.active {
          background-color: var(--clr-p2);

          .item-text {
            font-size: 0.938em;
            font-weight: 600;
          }
        }
      }
    }
  }

  .toList {
    text-align: center;
  }
}
</style>
