<template>
  <iframe
    src="https://eliasse.assemblee-nationale.fr/eliasse/index.html"
    frameborder="0"
    title="Vue du site Eliasse de l'assemblée nationale"
  />
</template>

<style scoped>
iframe {
  height: calc(100vh - 142px);
}
</style>
