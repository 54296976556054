<template>
  <template v-if="petitionsStore.items?.length > 0">
    <p>
      Ces pétitions sont celles de l'Assemblée Nationale ou rajoutées par les
      autres utilisateurs de Follaw.
    </p>

    <table class="petitions-table">
      <thead>
        <tr>
          <th class="author">Auteur</th>
          <th>Titre</th>
          <th
            class="clickable publish-date"
            :class="setActiveClass('published_at')"
            @click="filterOnHead('published_at')"
          >
            <div class="th-content">
              <span>Date de publication</span>
              <i class="fa-solid" :class="setChevron('published_at')"></i>
            </div>
          </th>
          <th
            class="clickable sign-number"
            :class="setActiveClass('signatures_number')"
            @click="filterOnHead('signatures_number')"
          >
            <div class="th-content">
              <span>Nombre de signatures</span>
              <i class="fa-solid" :class="setChevron('signatures_number')"></i>
            </div>
          </th>
          <th
            class="clickable"
            :class="setActiveClass('last_24h')"
            @click="filterOnHead('last_24h')"
          >
            <div class="th-content">
              <span>Dernières 24h</span>
              <i class="fa-solid" :class="setChevron('last_24h')"></i>
            </div>
          </th>

          <th>Origine</th>
          <th class="follaw">Follaw</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(petition, key, index) in petitionsStore.items" :key="index">
          <td class="petition-zoom author" @click="goToPetition(petition._id)">
            <DataOrMissingData :data="petition.author" />
          </td>

          <td class="petition-zoom" @click="goToPetition(petition._id)">
            {{ petition.title }}
          </td>

          <td class="petition-zoom" @click="goToPetition(petition._id)">
            <DataOrMissingData
              :data="
                petition.published_at
                  ? getDateShortFormat(petition.published_at)
                  : null
              "
            />
          </td>

          <td class="petition-zoom" @click="goToPetition(petition._id)">
            <b>
              <DataOrMissingData
                :data="
                  petition.signatures_number &&
                  formatNumbers(petition.signatures_number)
                "
              />
            </b>
            /
            {{ formatNumbers(petition.signatures_target) }}
          </td>

          <td class="petition-zoom" @click="goToPetition(petition._id)">
            <template v-if="petition.last_24h > 0"> + </template>
            {{ formatNumbers(petition.last_24h) }}
          </td>
          <td>
            <SourceLink :href="petition.url" :label="petition.origin" />
          </td>
          <td>
            <div class="follaw">
              <FollawButton
                :element-id="petition._id"
                :feature="FOLLAWED_FEATURES.PETITIONS"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <PaginationElement
      v-if="petitionsStore.item_count"
      :key="
        petitionsStore.selectedFilters.from ||
        petitionsStore.filters?.origin ||
        petitionsStore.selectedFilters.size
      "
      :current-page="petitionsStore.selectedFilters.from"
      :total-items="petitionsStore.item_count"
      :items-limit="petitionsStore.selectedFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <NoDataFollawed
    v-else-if="
      !petitionsStore.loading &&
      petitionsStore.isFollawedActive &&
      !petitionsStore.hasActiveFilters
    "
  />
  <NoData v-else />
</template>

<script setup>
import { useRouter } from "vue-router";
import DataOrMissingData from "@/components/petitions/atoms/DataOrMissingData.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import NoDataFollawed from "@/components/reusable/atoms/NoDataFollawed.vue";
import formatNumbers from "@/utils/formatNumbers";
import { usePetitionsStore } from "@/stores/petitions";
import { FOLLAWED_FEATURES } from "@/stores/user";
import { getDateShortFormat } from "@/utils/formatDate";

const router = useRouter();
const petitionsStore = usePetitionsStore();

// Zoom on one petition
const goToPetition = function (id) {
  router.push(`/petitions/${id}`);
};

// Change the current page
const changeCurrentPage = (from) => {
  const selected_filters = petitionsStore.selectedFilters;
  selected_filters.from = from;
  petitionsStore.fetchPetitionsList();
};

// Filtering using table head items
const filterOnHead = (sortProperty) => {
  petitionsStore.toggleSortByProperty(sortProperty);
  petitionsStore.fetchPetitionsList();
};

// Set the right chevron over the th
const setChevron = (param) => {
  const sortData = petitionsStore.selectedFilters.sort;

  if (sortData.property !== param) {
    return "fa-sort";
  }

  if (sortData.order === petitionsStore.ASC) {
    return "fa-sort-up";
  } else {
    return "fa-sort-down";
  }
};

// Set active class on sorter th elements
const setActiveClass = (sortProperty) => {
  const isSortedByProperty =
    petitionsStore.selectedFilters.sort.property === sortProperty;
  return isSortedByProperty ? "active" : "";
};
</script>

<style scoped lang="scss">
.petitions-table {
  thead {
    th {
      height: 4.0625em;
      padding: 10px;
      font-weight: 700;
      text-align: left;

      &.sign-number {
        width: 141px;
      }

      &.publish-date {
        width: 126px;
      }

      &.author {
        width: 170px;
      }

      &.follaw {
        width: 69px;
        text-align: center;
      }

      .th-content {
        display: flex;
      }
    }
  }

  tbody {
    tr {
      .petition-zoom {
        cursor: pointer;
      }

      td {
        &.author {
          color: var(--clr-s7);
        }

        .follaw {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
