<template>
  <div class="card">
    <h3>Voir le texte associé</h3>
    <p>
      <SourceLink
        :href="data.texte_associe_url"
        :label="data.type_declaration"
      />
    </p>
  </div>
</template>

<script setup>
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import { useLegiWorkStore } from "@/stores/legi-work";
import { useRoute } from "vue-router";

const store = useLegiWorkStore();
const route = useRoute();

const { stepIdx, data493idx } = route.params;

const data =
  store.legiWorkZoom.timeline[stepIdx].engagement_responsabilite_49_3[
    data493idx
  ];
</script>
