<template>
  <div class="content-wrapper">
    <BigStatCounter
      class="overview-questions"
      label="Questions"
      icon="fa-kit fa-questions"
      title-link-to="/questions"
      wrapper-class="active-color-questions"
      :value="store.stats.question_count"
      :subtype-values="store.stats.question_status_count"
    />
    <BigStatCounter
      class="overview-amendments"
      label="Amendements"
      icon="fa-kit fa-am"
      title-link-to="/amendments"
      wrapper-class="active-color-amendments"
      :value="store.stats.amendment_count"
      :subtype-values="store.stats.amendment_status_count"
    />
    <BigStatCounter
      class="overview-compte-rendus"
      label="Compte-Rendus"
      icon="fa-kit fa-cr"
      title-link-to="/reports"
      wrapper-class="active-color-reports"
      :value="store.compteRendusCount"
    />
    <BigStatDoubleCounter
      class="overview-laws"
      label="Projets de loi"
      label2="Propositions de loi"
      icon="fa-kit fa-dossierlegi"
      title1-link-to="/legislatives"
      title2-link-to="/legislatives"
      wrapper-class="active-color-laws"
      :value="store.stats.legi_proposition_count"
      :value2="store.stats.legi_project_count"
      :subtype-values="store.stats.legi_proposition_status_count"
      :subtype-values2="store.stats.legi_project_status_count"
    />
    <OverviewQuestionRanking
      class="overview-questions-ranking"
      :ranking="store.stats.most_asked_ministries || {}"
    />
    <OverviewGroupsGraph
      class="overview-groups"
      :items="store.stats.activities_per_group"
      :is-loading="false"
    />
  </div>
</template>

<script setup>
import BigStatCounter from "../atoms/BigStatCounter.vue";
import BigStatDoubleCounter from "../atoms/BigStatDoubleCounter.vue";
import OverviewQuestionRanking from "../atoms/OverviewQuestionRanking.vue";
import OverviewGroupsGraph from "../molecules/OverviewGroupsGraph.vue";

import { useLegiOverviewStore } from "@/stores/legi-overview";

const store = useLegiOverviewStore();
</script>

<style scoped lang="scss">
.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @container (width > 875px) {
    display: grid;
    grid-template:
      "question amendements compte-rendus"
      "laws laws laws"
      "questions-ranking groups groups";
  }

  @container (width > 1200px) {
    grid-template:
      "question amendements compte-rendus laws laws"
      "questions-ranking questions-ranking groups groups groups";
  }
}

.overview-questions {
  grid-area: question;
}

.overview-amendments {
  grid-area: amendements;
}

.overview-compte-rendus {
  grid-area: compte-rendus;
}

.overview-laws {
  grid-area: laws;
}

.overview-questions-ranking {
  grid-area: questions-ranking;
}

.overview-groups {
  grid-area: groups;
}

.fa-question {
  &:hover {
    background-color: var(--color-questions);
  }
}
</style>
