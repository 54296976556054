<template>
  <div v-if="store.isPublicationsLoading">
    <LoaderFullPage />
  </div>
  <template v-else-if="store.lobbiesPublications?.length">
    <div class="activities-wrapper" :class="{ zoomed: !isFollawed }">
      <template
        v-for="item in store.lobbiesPublications"
        :key="item._id || item.id_twitter"
      >
        <PublicationCard
          v-if="Object.values(Platforms).includes(item.data_type)"
          :post="item"
        />
      </template>
      <div class="pagination-wrapper">
        <PaginationElement
          v-if="store.publicationsCounts"
          :key="`${store.publicationsCounts}${store.selectedLobbiesActivitiesParams.from}${store.selectedLobbiesActivitiesParams.size}`"
          :total-items="store.publicationsCounts"
          :current-page="store.selectedLobbiesActivitiesParams.from"
          :items-limit="store.selectedLobbiesActivitiesParams.size"
          @change-current-page="changeCurrentPage"
        />
      </div>
    </div>
  </template>
  <div v-else class="card">
    <EmptySection :message="emptyMessage" />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import PublicationCard from "@/components/social-media/posts/molecules/PublicationCard.vue";
import EmptySection from "@/components/reusable/molecules/EmptySection.vue";
import { useLobbiesStore } from "@/stores/lobbies";
import { Platforms } from "@/utils/social360-platforms";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";

const store = useLobbiesStore();
const route = useRoute();

const isFollawed = ref(route.fullPath.includes("follawed") ? true : false);

const emptyMessage = computed(() => {
  return isFollawed.value
    ? "Vos organisations follawed n'ont pas d'activité."
    : "Cette organisation n'a pas d'activité.";
});

const changeCurrentPage = (from) => {
  store.selectedLobbiesActivitiesParams.from = from;
  isFollawed.value
    ? store.readFollawedPublications()
    : store.readOneLobbyPublications(route.params._id);
};
</script>
<style lang="scss" scoped>
.activities-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  &.zoomed {
    display: flex;
    flex-direction: column;
  }
}

.pagination-wrapper {
  grid-column: span 2;
}
</style>
