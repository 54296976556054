<template>
  <LoaderFullPage v-if="store.loadingFetch" />
  <template v-else>
    <div class="card">
      <form
        method="post"
        class="user-settings"
        @submit.prevent="handleSubmitForm"
      >
        <template v-for="key in inputFieldNames" :key="key">
          <div class="field" :class="`field-${key}`">
            <label :for="key">{{ inputFieldLabels[key] }} : *</label>
            <input :id="key" v-model="localData[key]" type="text" required />
            <span v-if="!isFormValid && !localData[key]" class="error"
              >{{ inputFieldLabels[key] }} est requis</span
            >
          </div>
        </template>

        <div class="field field-email">
          <label for="email">Email :</label>
          <input
            id="email"
            :value="store.user.email"
            disabled="true"
            type="email"
          />
          <div class="email-warning">
            Pour modifier votre adresse email, veuillez nous contacter via le
            chat.
          </div>
        </div>

        <CheckBox
          :checked="localData.newsletter"
          @change="(evt) => (localData.newsletter = evt.target.checked)"
        >
          Je veux recevoir
          <BaseLink href="https://mailchi.mp/saper-vedere/ijuklebci2">
            la newsletter
          </BaseLink>
        </CheckBox>

        <button
          class="btn-primary"
          :disabled="store.loadingUpdate"
          type="submit"
        >
          Enregistrer
        </button>
      </form>
    </div>
  </template>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useToast } from "vue-toastification";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import BaseLink from "@/components/general/atoms/BaseLink.vue";
import { useUserSettingsStore } from "@/stores/user-settings";

const toast = useToast();
const store = useUserSettingsStore();

const isFormValid = ref(true);

const inputFieldLabels = {
  first_name: "Prénom",
  last_name: "Nom de famille",
  occupation: "Fonction",
  sector: "Secteur",
};

const inputFieldNames = Object.keys(inputFieldLabels);

// Ignore the warning because we are using a watch() just below
const localData = ref({});

function validate() {
  isFormValid.value = inputFieldNames.every(
    (key) => localData.value[key].trim() !== ""
  );

  return isFormValid.value;
}

// Compare old and new values and select only the changed values
function findChanges(original, updated) {
  const changes = {};
  Object.keys(updated).forEach((key) => {
    if (updated[key] !== original[key]) {
      changes[key] = updated[key];
    }
  });
  return changes;
}

// Submit the form with only the changed keys
const handleSubmitForm = () => {
  if (validate()) {
    const changes = findChanges(store.user, localData.value);
    if (Object.keys(changes).length > 0) {
      store.updateUserSettings(changes);
    } else {
      toast.info("Aucun changement à enregistrer");
    }
  }
};

onMounted(async () => {
  await store.getUserSettings();
  localData.value = {
    ...Object.fromEntries(inputFieldNames.map((key) => [key, store.user[key]])),
    newsletter: store.user.newsletter,
  };
});
</script>

<style lang="scss">
.user-settings {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .field {
    display: flex;
    gap: 4px;
  }
  .field {
    flex-direction: column;
  }
  .newsletter-wrapper {
    padding: 18px 0;
  }
  .error {
    margin-left: 10px;
    color: var(--clr-e300);
    font-style: italic;
    font-size: 0.875em;
  }

  > button {
    align-self: flex-start;
  }

  input[type="text"],
  input[type="email"] {
    max-width: 250px;
  }

  .field-email {
    input#email {
      cursor: not-allowed;
    }

    &:hover {
      .email-warning {
        color: var(--clr-e300);
      }
    }
  }
}

p {
  margin: 0;
  line-height: var(--lh-normal);
}

.current-plan {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .plan-infos {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    padding: 8px 16px;
    background-color: var(--clr-n2);

    .plan-icon {
      width: 22px;
      height: 22px;
    }

    .plan-end {
      margin-left: auto;

      .date {
        font-weight: var(--fw-semibold);
      }
    }
  }
}

.plan-compare {
  text-decoration: underline;
}

.to-stripe {
  align-self: flex-start;
}
</style>
