<template>
  <div id="side-menu" :class="{ isMenuOpen: !isMenuClosed }">
    <!-- navigation -->
    <nav data-product-tour="step-3">
      <ul v-for="menu_element in menu" :key="menu_element">
        <li>
          <RouterLink
            v-if="menu_element.path"
            :to="menu_element.path"
            :aria-label="`Vers la page ${menu_element.name}`"
          >
            <MenuPrimaryTab
              :responsive-menu="isMenuClosed"
              :tab="menu_element"
            />
          </RouterLink>

          <RouterLink
            v-else-if="
              menu_element.meta?.permission_level &&
              !userSettingsStore.hasPermission(
                menu_element.meta.permission_level
              ) &&
              menu_element.locked_route
            "
            :to="menu_element.locked_route.path"
            aria-label="Vers la page d'explication de page inaccessible"
          >
            <MenuPrimaryTab
              :responsive-menu="isMenuClosed"
              :tab="menu_element"
            />
          </RouterLink>

          <div v-else class="englobe_tabs">
            <div @click="toggleSecondaryTabs(menu_element.name)">
              <MenuPrimaryTab
                :expanded-tab="expandedTab"
                :responsive-menu="isMenuClosed"
                :tab="menu_element"
              />
            </div>

            <div
              v-if="expandedTab === menu_element.name"
              v-on-click-outside="onClickOutsideSecondaryMenu"
              :class="{
                englobe_secondary_tabs: true,
                isSecondaryResponsive: isMenuClosed,
              }"
            >
              <div v-show="isMenuClosed" class="secondary_title">
                {{ menu_element.name }}
              </div>
              <ul class="secondary_list">
                <li
                  v-for="tab in menu_element.secondary_tabs.filter(
                    (t) => !t.redirect && !t.hidden
                  )"
                  :key="tab.path"
                >
                  <RouterLink :to="tab.path" @click="onClickMenuLink">
                    <div
                      class="secondary_tab"
                      :class="{
                        active: isCurrentTab(tab, route),
                      }"
                    >
                      {{ tab.display_name || tab.name }}
                      <span
                        v-if="
                          tab.meta?.permission_level &&
                          !userSettingsStore.hasPermission(
                            tab.meta.permission_level
                          )
                        "
                      >
                        <PermissionLevelIcon
                          class="permission-level-icon"
                          :permission-level="tab.meta.permission_level"
                        />
                      </span>
                    </div>
                  </RouterLink>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </nav>

    <!-- toggle menu button -->
    <div class="footer">
      <button
        :aria-label="isMenuClosed ? 'Étendre le menu' : null"
        @click="toggleIsClosed"
      >
        <i
          class="fa-regular"
          :class="isMenuClosed ? 'fa-chevrons-right' : 'fa-chevrons-left'"
        ></i>
        <span v-if="!isMenuClosed">&nbsp;Réduire le menu</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import { useRoute } from "vue-router";

import MenuPrimaryTab from "@/components/general/atoms/MenuPrimaryTab.vue";
import PermissionLevelIcon from "../atoms/PermissionLevelIcon.vue";
import menu from "@/utils/side_menu";
import { isCurrentTab } from "@/utils/navigation.js";
import { useUserSettingsStore } from "@/stores/user-settings";

const route = useRoute();
const userSettingsStore = useUserSettingsStore();

const isMenuClosed = ref(window.innerWidth <= 767);
// If a tab is already selected, initialize it as expanded
const expandedTab = ref(
  menu.find(
    (tab) =>
      !isMenuClosed.value && tab.secondary_tabs && isCurrentTab(tab, route)
  )?.name || null
);

function handleResize() {
  isMenuClosed.value = window.innerWidth <= 767;
}
onMounted(() => {
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// Watch route changes to update the expanded tab
watch(
  () => route.name,
  () => {
    expandedTab.value = menu.find(
      (tab) =>
        !isMenuClosed.value && tab.secondary_tabs && isCurrentTab(tab, route)
    )?.name;
  }
);

function toggleSecondaryTabs(tabName) {
  expandedTab.value = expandedTab.value !== tabName ? tabName : null;
}

function toggleIsClosed() {
  isMenuClosed.value = !isMenuClosed.value;
  if (isMenuClosed.value) {
    expandedTab.value = null;
  }
}

function onClickOutsideSecondaryMenu(evt) {
  if (isMenuClosed.value) {
    expandedTab.value = null;
    evt.stopPropagation();
  }
}

function onClickMenuLink() {
  if (isMenuClosed.value) {
    expandedTab.value = null;
  }
}
</script>

<style scoped lang="scss">
#side-menu {
  display: flex;
  flex-direction: column;

  nav {
    transition: width 0.4s ease;
    width: 55px;
    overflow-x: hidden;
  }

  .footer {
    margin-top: auto;
    height: 50px;
    width: 100%;
    align-self: flex-start;

    button {
      padding: 0;
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      color: white;
      background-color: transparent;

      box-shadow: none;
      font-size: 0.9em;

      overflow-x: hidden;
      white-space: nowrap;

      &:hover {
        background-color: var(--clr-n8);
      }
    }
  }

  &.isMenuOpen {
    nav {
      width: 255px;
    }

    button {
      justify-content: flex-start;

      i {
        transition: padding 0.3s ease;
        padding-left: 25px;
        padding-right: 5px;
      }

      &:hover {
        i {
          padding-left: 20px;
          padding-right: 10px; // compensate the padding so the next element stays in place
        }
      }
    }
  }

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .englobe_secondary_tabs {
    .secondary_tab {
      padding: 10px 10px 10px 30px;
      font-size: 0.9em;
      font-weight: 300;
      white-space: nowrap;

      position: relative;

      background-color: var(--clr-p8);
      color: var(--clr-n1);

      &:hover {
        background-color: var(--clr-p4);
      }

      &.active {
        font-weight: bold;
      }
    }

    &.isSecondaryResponsive {
      --secondary-tab-bg-color: white;
      --secondary-tab-hover-color: var(--clr-p2);
      --secondary-tab-txt-color: var(--clr-p9);

      background-color: transparent;

      position: absolute;
      left: 50px;
      transform: translateY(-25px);

      .secondary_title {
        padding: 8px 10px 8px 10px;
        white-space: nowrap;

        background-color: var(--secondary-tab-bg-color);
        color: var(--secondary-tab-txt-color);

        margin-bottom: 2px;

        border-radius: 3px;
        box-shadow: 0px 3px 6px #00000029;
      }

      .secondary_list {
        white-space: nowrap;

        background-color: var(--secondary-tab-bg-color);
        color: var(--secondary-tab-txt-color);
        border-radius: 3px;
        box-shadow: 0px 3px 6px #00000029;

        width: 250px;

        .secondary_tab {
          padding: 8px 10px 8px 10px;

          &:hover {
            font-weight: bold;
            background-color: transparent;
          }

          &.active {
            font-weight: bold;
            background-color: var(--secondary-tab-hover-color);
          }
        }
      }
    }
  }
}

:deep(.permission-level-icon) {
  width: 20px;
  height: 20px;

  display: inline;
  position: absolute;
  right: 8px;
  top: 7px;
}

@media (max-width: 767px) {
  #side-menu {
    .footer {
      margin-top: 2rem;
      // Temporary workaround to help distinguish the toggle menu from the other features
      border-top: solid 0.5px rgba(255, 255, 255, 0.2);
      border-bottom: solid 0.5px rgba(255, 255, 255, 0.2);
    }
  }
}
</style>
