<template>
  <div class="template">
    <BreadCrumbs :crumbs="crumbsArray" />
    <LoaderFullPage v-if="store.isReportLoading" />
    <div v-else-if="store.report" class="card">
      <div class="header">
        <div>{{ getDayTimeFormat(store.report.created_at) }}</div>
        <SourceLink
          v-if="store.report.url"
          :href="store.report.url"
          label="Source"
        />
      </div>
      <div class="title-row">
        <InstitutionBadge :institution="store.report.institution" />
        <div class="title-col">
          <div class="title">{{ store.report.title }}</div>
          <div class="report-type">{{ store.report.type_ }}</div>
        </div>
      </div>
      <div class="body">
        <OccurrencesCollapse
          v-if="hasOccurrences"
          :occurrences="store.report.occurrences"
        />
        <FloatingBrowseOccurrences v-if="hasOccurrences" />

        <div v-if="store.report.personalities.length > 0" class="card">
          <ListOfDeputies
            :deputies-list="store.report.personalities"
            title="Participant(e)s"
          />
        </div>

        <!-- eslint-disable vue/no-v-html -->
        <div
          class="report-content typo-body-regular"
          v-html="store.report.content"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
    <ErrorPage
      v-else
      :error="{ message: 'Impossible de charger ce rapport' }"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

import BreadCrumbs from "@/components/reusable/atoms/BreadCrumbs.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import { getDayTimeFormat } from "@/utils/formatDate";
import InstitutionBadge from "@/components/legi-work/atoms/InstitutionBadge.vue";
import OccurrencesCollapse from "@/components/reusable/atoms/OccurrencesCollapse.vue";
import ListOfDeputies from "@/components/reusable/molecules/ListOfDeputies.vue";
import FloatingBrowseOccurrences from "@/components/reusable/atoms/FloatingBrowseOccurrences.vue";
import ErrorPage from "@/components/reusable/atoms/ErrorPage.vue";
import { useLobbiesStore } from "@/stores/lobbies";

const store = useLobbiesStore();
const route = useRoute();

const hasOccurrences = computed(
  () => Object.keys(store.report.occurrences || {}).length > 0
);

const crumbsArray = computed(() => [
  {
    text: "Lobbies",
    to: { name: "Lobbies" },
  },
  route.params._id === "follawed"
    ? {
        text: "Actualités Follawed",
        to: { name: "Lobbies Follawed - Leurs Actualités" },
      }
    : {
        text: store.lobbyZoom?.name ?? "Lobby",
        to: {
          name: "Lobby Zoom - Actualités",
          params: { _id: route.params._id },
        },
      },

  {
    text: "Compte Rendu",
  },
]);

// Get the lobby to display the name in the breadcrumbs
if (
  route.params._id !== "follawed" &&
  store.lobbyZoom?.national_id !== route.params._id
) {
  store.readOneLobby(route.params._id);
}

store.fetchOneReport(route.params.docRef);
</script>

<style scoped lang="scss">
.header {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.title-row {
  display: flex;
  gap: 10px;

  color: var(--clr-s8);

  margin-bottom: 10px;

  .title-col {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 700;
    }

    .report-type {
      text-transform: capitalize;
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.report-content {
  color: var(--clr-n8);

  :deep(.personality) {
    padding-top: 10px;
    font-weight: 600;
  }
}
</style>
