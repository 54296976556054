<template>
  <form
    id="login-form"
    novalidate
    @submit.prevent="formSubmit"
    @keydown.enter="onEnterKeyPress"
  >
    <h5 class="title">Connexion</h5>
    <!-- Email field -->
    <FormInput
      v-model.trim="state.user_email.value"
      label="Email"
      name="log-email"
      type="email"
      :disabled="state.needToRefreshToken || isComingFromRegister"
      :error="!state.user_email.isValid ? state.error : null"
      required
    />
    <!-- Digit code inputs -->
    <template
      v-if="
        (isComingFromRegister || state.needToRefreshToken) &&
        !state.error?.includes('banned')
      "
    >
      <p>
        Nous vous avons envoyé un lien de connexion. Cliquez sur le lien pour
        accéder à la plateforme.
      </p>
      <p>
        Vous pouvez fermer cette fenêtre ou entrer le code manuellement
        ci-dessous.
      </p>
      <div class="inputs-and-error">
        <div id="digitFields" class="digicode-inputsfields">
          <input
            v-for="(digitItem, index) in digicodeNumbers"
            :key="index"
            v-model="digitItem.value"
            type="text"
            :maxlength="1"
            :class="{ 'error-input': !digitItem.isValid }"
            :aria-label="`Chiffre ${index} du code de connexion`"
            @keydown="keypressUnitIsValid(digitItem, $event)"
            @paste="pasteDigits($event)"
            @keydown.delete="backspaceFocus($event)"
            @keydown.left="moveFocus($event, 'l')"
            @keydown.right="moveFocus($event, 'r')"
          />
        </div>
        <!-- Error display template -->
        <p
          v-if="state.error"
          class="message"
          :class="[state.statusCode === 202 ? '' : 'error-message']"
        >
          <i :class="{ 'fa-solid fa-ban': state.statusCode !== 202 }"></i>
          {{ state.error }}
        </p>
      </div>
    </template>
    <!-- Submit buttons groups -->
    <div class="btns" :class="{ 'is-mobile': isMobile }">
      <!-- There is two submit buttons because their order is different on mobile and desktop -->
      <button
        v-if="isMobile"
        type="submit"
        class="btn-primary"
        :disabled="
          state.disableSubmitButton || state.error?.includes?.('banned')
        "
      >
        Se connecter
      </button>
      <button
        v-if="state.needToRefreshToken && !state.error?.includes?.('banned')"
        class="btn-secondary"
        :disabled="state.disableAskNewCodeButton"
        @click="askNewCode($event)"
      >
        Renvoyer nouveau code
        <!-- Display timer in seconds -->
        <span>{{ state.seconds }}</span>
      </button>
      <button
        v-if="!isMobile"
        type="submit"
        class="btn-primary"
        :disabled="
          state.disableSubmitButton || state.error?.includes?.('banned')
        "
      >
        Se connecter
      </button>
    </div>
    <p v-if="isMobile && !state.needToRefreshToken" class="go-to-register">
      Vous n'avez pas de compte ?
      <RouterLink to="/register">Inscription</RouterLink>
    </p>
    <!-- Show the contact by email message if the login process failed -->
    <p
      v-if="
        state.error &&
        state.needToRefreshToken &&
        !state.error?.includes?.('banned')
      "
      class="contact-message"
    >
      Veuillez réessayer. Si le problème persiste, contactez-nous.
    </p>

    <p>
      Un problème pour vous connecter ? Envoyez-nous un message à
      <a
        href="mailto:support@follaw.sv?subject=Problème de connexion à Follaw-sv"
        target="_blank"
      >
        support@follaw.sv</a
      >.
    </p>
  </form>
</template>

<script setup>
import { reactive, computed } from "vue";
import FormInput from "@/components/reusable/atoms/FormInput.vue";
import { validateEmail } from "@/utils/helper.utils";
import { useAuthStore } from "@/stores/auth";
import { useRegisterStore } from "@/stores/register";

const authStore = useAuthStore();
const registerStore = useRegisterStore();

defineProps({
  isMobile: { type: Boolean, default: false },
});

const isComingFromRegister = computed(() =>
  registerStore.user?.email ? true : false
);

const state = reactive({
  user_email: {
    value: registerStore.user?.email ?? "",
    isValid: true,
  },
  disableSubmitButton: false,
  disableAskNewCodeButton: false,
  error: null,
  seconds: ``,
  statusCode: null,
  needToRefreshToken: registerStore.user?.email ? true : false,
});

const digicodeNumbers = reactive({
  [1]: {
    value: "",
    isValid: true,
  },
  [2]: {
    value: "",
    isValid: true,
  },
  [3]: {
    value: "",
    isValid: true,
  },
  [4]: {
    value: "",
    isValid: true,
  },
  [5]: {
    value: "",
    isValid: true,
  },
  [6]: {
    value: "",
    isValid: true,
  },
});

// Buid the digit code
function getCode() {
  let rawCode = "";
  for (const prop in digicodeNumbers) {
    rawCode = rawCode + digicodeNumbers[prop].value;
  }
  return rawCode;
}

// Paste digit code on inputs event
function pasteDigits(evt) {
  let paste = (evt.clipboardData || window.clipboardData).getData("text");
  paste = paste.split("");
  for (let i = 0; i < Object.keys(digicodeNumbers).length; i++) {
    if (isNaN(Number(paste[i]))) {
      digicodeNumbers[i + 1].value = "";
      digicodeNumbers[i + 1].isValid = false;
    } else {
      digicodeNumbers[i + 1].value = Number(paste[i]);
      digicodeNumbers[i + 1].isValid = true;
    }
  }
  digicodeIsValid(getCode());
}

// Getting digit on Keypress
function keypressUnitIsValid(digicodeNumber, evt) {
  state.statusCode = "";
  evt.prevent;
  if (isNaN(evt.key) && evt.key !== "Enter") {
    digicodeNumber.value = "";
    digicodeNumber.isValid = false;
    state.error = "Veuillez entrer uniquement des chiffres";
    evt.stopPropagation();
    return false;
  } else if (evt.key === "Enter") {
    onEnterKeyPress(evt);
  } else {
    digicodeNumber.value = evt.key;
    digicodeNumber.isValid = true;
    state.error = "";
    // Moving to the next digit code field
    evt.target.nextElementSibling?.focus();
    return true;
  }
}
// Global digit code validation
function digicodeIsValid(code) {
  if (!code) {
    state.error = "Veuillez renseigner le code de validation";
    state.statusCode = null;
    return false;
  } else if (code.length !== 6) {
    state.error = "Le nombre de caractère est incorrect";
    state.statusCode = null;
    return false;
  } else if (state.needToRefreshToken) {
    if (!checkDigicodeItem(code)) {
      state.error =
        "Votre code ne correspond pas à celui que nous vous avons envoyé.";
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}
// Check every character in the code
function checkDigicodeItem(code) {
  for (let i = 0; i < 6; i++) {
    if (isNaN(Number(code[i]))) {
      state.error = "Code incorrect " + `${code[i]}`;
      digicodeNumbers[i + 1].isValid = false;
      return false;
    }
  }
  return true;
}
// Allow backspace to change focus and delete previous input
function backspaceFocus(evt) {
  if (!evt.target.value) {
    // Moving to the previous sibling
    evt?.target?.previousElementSibling?.focus();
  }
}
// When press the enter key fire this function
function onEnterKeyPress(evt) {
  if (evt.target?.value && !state.disableSubmitButton) {
    formSubmit(evt);
  }
}
// Move focus to the next digit code field
function moveFocus(evt, direction) {
  if (direction === "r") {
    evt?.target?.nextElementSibling?.focus();
  }
  if (direction === "l") {
    evt?.target?.previousElementSibling?.focus();
  }
}

// Disable the asking button with delay
function disableAskNewCodeButtonFunction() {
  state.disableAskNewCodeButton = true;
  // Set 1 minutes as counter delay
  let distance = 60000;
  const decrementer = setInterval(() => {
    // Find the distance between now and the delay
    distance = distance - 1000;
    // Time calculations minutes and seconds
    state.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    // If the count down is finished, enable the renew token button
    if (distance < 0) {
      clearInterval(decrementer);
      state.disableAskNewCodeButton = false;
      state.seconds = null;
    }
  }, 1000);
}

// Ask the new code for refreshing the token
async function askNewCode(evt) {
  evt.prevent;
  disableAskNewCodeButtonFunction();
  // send the request with response and interpret different code status to show the right message with right design
  const response = await authStore.loginRequest({
    user_email: state.user_email.value,
  });
  if (response?.statusCode) {
    state.error = response.data.message;
    state.statusCode = response?.statusCode;
  }

  if (response?.error) {
    state.error = response.error.data?.message;
    state.statusCode = "";
  }
}

// Validate form before sending the request to the server
function formValidation() {
  // Concerning this form only email have to be validate for the moment
  if (!state.user_email.value.length) {
    state.error = "Vous n'avez pas renseigné votre e-mail";
    state.user_email.isValid = false;
    return false;
  } else if (!validateEmail(state.user_email.value)) {
    state.error =
      "Votre e-mail ne correspond pas à un format conforme. Veuillez le corriger";
    state.user_email.isValid = false;
    return false;
  } else if (state.needToRefreshToken) {
    if (!digicodeIsValid(getCode())) {
      return false;
    } else {
      return true;
    }
  } else {
    state.user_email.isValid = true;
    return true;
  }
}

// Submit the form for login
async function formSubmit(evt) {
  evt.prevent;
  const code = getCode();

  // Validate the form
  if (formValidation()) {
    state.disableSubmitButton = true;
    setTimeout(() => (state.disableSubmitButton = false), 3000);
    // Check if the digit code is valid and exist then send the PATCH request for refreshing tokent with code
    if (state.needToRefreshToken) {
      const response = await authStore.createTokenWithDigicode({
        user_email: state.user_email.value,
        code: code,
      });
      if (response?.statusCode === 200) {
        state.needToRefreshToken = false;
        state.statusCode = null;
      }
      if (response?.statusCode === 401) {
        state.needToRefreshToken = false;
        state.statusCode = response?.statusCode;
        state.seconds = 18000000;
      }
      if (response?.error) {
        state.error = response.error.data?.message || response.error;
        state.statusCode = response.error.status;
      }
    }

    // Login request without digit code
    if (!state.needToRefreshToken) {
      // When the user is not banned or authorized
      if (state.statusCode !== 401) {
        const response = await authStore.loginRequest({
          user_email: state.user_email.value,
        });
        // Check if the email exist on the database then server will send and email with digit code numbers to refresh the token
        if (response?.statusCode === 202) {
          state.error = response.data.message;
          state.needToRefreshToken = true;
          state.disableAskNewCodeButton = true;
          disableAskNewCodeButtonFunction();
          state.statusCode = 202;
        }
        // When login is successful
        if (response?.statusCode === 200) {
          state.needToRefreshToken = false;
          state.statusCode = response?.statusCode;
        }
        if (response?.statusCode === 401) {
          state.needToRefreshToken = false;
          state.statusCode = response?.error.status;
          state.seconds = 18000000;
        }
        // Getting the error message
        if (response?.error) {
          state.error = response.error.data.message;
          state.statusCode = response?.error.status;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

#login-form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .title {
    text-align: center;
  }
}

.digicode-inputsfields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 3px;

  input[type="text"] {
    text-align: center;
    font-size: 2em;
    font-weight: 200;
    width: 100%;
    height: 3.563rem;

    .error-input {
      border: 1px var(--clr-e200) solid;
    }
  }
}

.btns {
  display: flex;
  gap: 8px;

  &.is-mobile {
    flex-direction: column;
  }

  button:first-child {
    flex: 1;
  }
}

.message {
  margin-top: 8px;
}

.error-message {
  color: var(--clr-e200);
}

.go-to-register {
  text-align: center;
}

.contact-message {
  text-align: center;
  font-size: var(--size-small);
  line-height: var(--lh-small);
}
</style>
