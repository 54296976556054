<template>
  <LoaderFullPage v-if="store.loadingFetch" />
  <template v-else>
    <div class="card current-plan">
      <h6>Abonnement actuel</h6>
      <div class="plan-infos">
        <img
          v-if="store.user.permission_level === 'TRIAL'"
          src="@/assets/img/stars-trial.svg"
          class="plan-icon"
        />
        <h5>{{ currentPlanName }}</h5>
        <PermissionLevelIcon
          v-if="
            store.user.permission_level === 'BASIC' ||
            store.user.permission_level === 'SOCIETAL'
          "
          class="plan-icon"
          :permission-level="store.user.permission_level"
        />
        <span
          v-if="store.user.permission_level !== 'FREE' && !isTeamsPlan"
          class="plan-end"
        >
          {{
            store.user.permission_level === "TRIAL" ||
            store.user.stripe_information.canceled
              ? "Prend fin le "
              : "Renouvellement le "
          }}
          <span class="date"
            >{{
              dayjs(store.user.stripe_information.end_date).format(
                "DD MMMM YYYY"
              )
            }}.</span
          >
        </span>
      </div>
      <p v-if="store.user.permission_level === 'TRIAL'">
        En tant que nouvel utilisateur, vous profitez du plan Sociétal pendant
        un mois.
      </p>
      <p v-if="store.user.stripe_information.canceled">
        Vous serez automatiquement rétrogradé à l'abonnement Gratuit à
        l'expiration de votre abonnement. Pour continuer à profiter des
        avantages, veuillez renouveler votre abonnement.
      </p>
      <p>
        <BaseLink
          class="plan-compare"
          href="https://www.follaw.sv#pricing-table"
        >
          Comparer les abonnements
        </BaseLink>
      </p>
      <h6>Facturation</h6>
      <template v-if="!isTeamsPlan">
        <p v-if="store.user.permission_level === 'TRIAL'">
          Vous serez automatiquement rétrogradé à l'abonnement Gratuit à
          l'expiration de la période d'essai. Pour continuer à profiter des
          avantages, veuillez ajoutez vos informations de paiement.
        </p>
        <p v-else>
          Information sur votre méthode de paiement, vos factures ou le
          renouvellement de votre abonnement
        </p>
        <BaseLink
          class="btn-primary to-stripe"
          :href="store.user.stripe_customer_portal_url"
        >
          Gérer mon abonnement
        </BaseLink>
      </template>
      <p v-else>
        Votre plan est administré par votre équipe, veuillez contacter votre
        responsable interne pour plus d'informations.
      </p>
    </div>
    <div class="month-year-radio">
      <CheckboxRadioGroup
        v-model="selectedBillingPeriod"
        :options="billingPeriod"
      />
    </div>
    <div class="plan-row">
      <div
        class="card plan"
        :class="{ current: store.user.permission_level === 'FREE' }"
      >
        <div class="plan-title">Gratuit</div>
        <div class="plan-price">
          <div class="big-price-row">
            <div class="big-price">Gratuit</div>
          </div>
          <div v-if="isYearly" class="monthly-price-row opacity-0">
            <div class="big-price">Gratuit</div>
            <span></span>
          </div>
        </div>
        <GetPlanButton
          plan="FREE"
          period="FREE"
          :current-plan="store.user.permission_level"
        />
        <p class="plan-short-description">
          Découvrez les possibilités de Follaw.sv en explorant nos veilles
          thématiques préconfigurées
        </p>
        <div class="plan-features">
          <p class="features-title">Inclus dans le plan Gratuit</p>
          <ul class="features-list">
            <li>Plus de 800 veilles thématiques préconfigurées</li>
            <li>
              Agendas AN, Sénat, Président de la République, Premier Ministre,
              Ministres, etc
            </li>
            <li>Dossiers législatifs</li>
            <li>Questions, amendements et comptes-rendus</li>
            <li>Accès au JORF</li>
          </ul>
        </div>
      </div>
      <div
        class="card plan"
        :class="{ current: store.user.permission_level === 'BASIC' }"
      >
        <div class="plan-title">
          Essentiel
          <PermissionLevelIcon class="plan-icon" permission-level="BASIC" />
        </div>
        <div class="plan-price">
          <div class="big-price-row">
            <div class="big-price">{{ isYearly ? "83,25€" : "99€" }}</div>
            <span
              >TTC / par mois<br /><template v-if="!isYearly"
                >facturé mensuellement</template
              ></span
            >
          </div>
          <div v-if="isYearly" class="monthly-price-row">
            <div class="big-price">999€</div>
            <span>facturé annuellement</span>
          </div>
        </div>
        <GetPlanButton
          plan="BASIC"
          :period="isYearly ? 'YEAR' : 'MONTH'"
          :current-plan="store.user.permission_level"
        />
        <p class="plan-short-description">
          Passez à l'étape supérieure avec une veille configurée spécifiquement
          pour vos thématiques et des notifications en temps réel
        </p>
        <div class="plan-features">
          <div class="features-text">
            <p class="features-title">Inclus dans le plan Essentiel</p>
            <p>Toute les fonctionnalités gratuites, mais aussi :</p>
          </div>
          <ul class="features-list">
            <li>Alertes</li>
            <li>Récapitulatifs</li>
            <li>Export des données</li>
            <li>Envoi de mails</li>
            <li>1 veille personnalisée</li>
            <li>Enregistrement de veilles</li>
          </ul>
        </div>
      </div>
      <div
        class="card plan"
        :class="{
          current:
            store.user.permission_level === 'SOCIETAL' ||
            store.user.permission_level === 'TRIAL',
        }"
      >
        <div class="plan-title">
          Sociétal
          <PermissionLevelIcon class="plan-icon" permission-level="SOCIETAL" />
        </div>
        <div class="plan-price">
          <div class="big-price-row">
            <div class="big-price">
              {{ isYearly ? "249,91€" : "299€" }}
            </div>
            <span
              >TTC / par mois<br /><template v-if="!isYearly"
                >facturé mensuellement</template
              ></span
            >
          </div>
          <div v-if="isYearly" class="monthly-price-row">
            <div class="big-price">2999€</div>
            <span>facturé annuellement</span>
          </div>
        </div>
        <GetPlanButton
          plan="SOCIETAL"
          :period="isYearly ? 'YEAR' : 'MONTH'"
          :current-plan="store.user.permission_level"
        />
        <p class="plan-short-description">
          Une offre 360° qui vous permet d'étendre votre veille à d'autres
          secteurs et de bénéficier d'un accompagnement dédié
        </p>
        <div class="plan-features">
          <div class="features-text">
            <p class="features-title">Inclus dans le plan Sociétal</p>
            <p>Toute les fonctionnalités essentielles, mais aussi :</p>
          </div>
          <ul class="features-list">
            <li>Formation de 2 heures</li>
            <li>Social Medias</li>
            <li>Pétitions</li>
            <li>Nominations</li>
          </ul>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import dayjs from "dayjs";
import { ref, computed } from "vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import BaseLink from "@/components/general/atoms/BaseLink.vue";
import CheckboxRadioGroup from "@/components/reusable/molecules/CheckboxRadioGroup.vue";
import GetPlanButton from "@/components/user-settings/atoms/GetPlanButton.vue";
import PermissionLevelIcon from "@/components/general/atoms/PermissionLevelIcon.vue";
import { useUserSettingsStore } from "@/stores/user-settings";

const store = useUserSettingsStore();

const isTeamsPlan = computed(() =>
  store.user.permission_level.includes("TEAMS")
);

const currentPlanName = computed(() => {
  switch (store.user.permission_level) {
    case "SOCIETAL":
      return "Sociétal";
    case "TEAMS_SOCIETAL":
      return "Sociétal (équipe)";
    case "BASIC":
      return "Essentiel";
    case "TEAMS_BASIC":
      return "Essentiel (équipe)";
    case "TRIAL":
      return "Période d'essai";
    case "FREE":
    default:
      return "Gratuit";
  }
});

const billingPeriod = ref([
  { value: "MONTH", text: "Facture mensuelle" },
  { value: "YEAR", text: "Facture annuelle" },
]);
const selectedBillingPeriod = ref("MONTH");
const isYearly = computed(() => selectedBillingPeriod.value === "YEAR");
</script>

<style lang="scss">
p {
  margin: 0;
  line-height: var(--lh-normal);
}

.current-plan {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .plan-infos {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    padding: 8px 16px;
    background-color: var(--clr-n2);

    .plan-icon {
      width: 22px;
      height: 22px;
    }

    .plan-end {
      margin-left: auto;

      .date {
        font-weight: var(--fw-semibold);
      }
    }
  }

  .plan-compare {
    text-decoration: underline;
  }

  .to-stripe {
    align-self: flex-start;
  }
}

.plan-icon {
  width: 22px;
  height: 22px;
}

.plan-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  @container (min-width: 650px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  gap: 8px;

  .plan {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &.current {
      border: 2px solid var(--clr-p5);
    }

    .plan-title {
      display: flex;
      align-items: baseline;
      gap: 16px;

      font-size: 27.64px;
      font-weight: 600;
      line-height: 130%;
    }

    .plan-short-description {
      min-height: 72px;
    }

    .plan-price {
      .big-price-row {
        display: flex;
        gap: 8px;
        align-items: center;

        font-size: 13px;

        .big-price {
          font-size: 33.18px;
          font-weight: 400;
          line-height: 150%;
        }
      }

      .monthly-price-row {
        display: flex;
        gap: 8px;
        align-items: center;

        .big-price {
          font-size: 16px;
          font-weight: 600;
          line-height: 150%; /* 24px */
        }
      }
    }

    .plan-features {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .features-text {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .features-title {
        font-weight: var(--fw-semibold);
      }

      .features-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0;
        padding-left: 0;
        line-height: var(--lh-normal);
        list-style-position: inside;

        li::marker {
          font-family: "Font Awesome 6 Pro";
          color: var(--clr-s6);
          content: "\f00c  "; /* Unicode for the desired Font Awesome icon */
          margin-right: 8px;
        }
      }
    }

    .btn-primary {
      align-self: flex-start;
    }
  }
}

.custom-dashboard-tooltip-trigger {
  color: var(--clr-s7);
}

.custom-dashboard-tooltip-text {
  width: 100%;
}

.plan-compare {
  align-self: flex-start;
}
</style>
