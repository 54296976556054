<template>
  <RouterLink :to="linkUrl" class="authors-card">
    <div class="card card-hover authors-card">
      <div class="authors-card__wrapper">
        <div class="authors-card__header">
          <div class="authors-card__header__tag">
            <StakeholderTag :stakeholder="author.stakeholder" />
            <ThemeTag v-if="author.theme" :theme="author.theme" />
            <GroupTag
              v-if="author.group"
              :background-color="author.group.color"
            >
              {{ author.group.acronym }}
            </GroupTag>
          </div>

          <FollawButton
            :element-id="author._id"
            :feature="FOLLAWED_FEATURES.TWEET_AUTHORS"
          />
        </div>

        <SeparatorLine />

        <div class="authors-card__main">
          <CardProfilePicture
            :picture-url="author.photo"
            class="authors-card__main__picture"
            :size="70"
          />
          <div class="authors-card__main__infos">
            <div class="authors-card__main__infos__header">
              <!-- Name and twitter-id -->
              <span class="title-large-regular name">
                {{ author.name }}
              </span>
              <PrmLink
                v-if="author.is_prm"
                :to="`/prm-national/${author._id}`"
              />
            </div>

            <div class="authors-card__main__infos__handles">
              <template v-for="handle in socialHandles" :key="handle">
                <span
                  v-if="handle.url"
                  class="authors-card__main__infos__handle"
                >
                  <BaseLink
                    :href="handle.url"
                    :aria-label="`Lien vers ${handle.url}`"
                  >
                    <i :class="handle.icon" :style="{ color: handle.color }" />
                  </BaseLink>
                </span>
              </template>
            </div>
          </div>
        </div>

        <!-- eslint-disable vue/no-v-html -->
        <p
          class="typo-body-regular authors-card__main__infos__description"
          v-html="
            author.x_information?.description ||
            'Pas de biographie X disponible'
          "
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>

      <div class="authors-card__footer">
        <Component
          :is="metric.routeUrl ? RouterLink : 'div'"
          v-for="metric in metrics"
          :key="metric.label"
          :to="metric.routeUrl || undefined"
          class="authors-card__footer__metric"
          :class="{ clickable: metric.routeUrl }"
        >
          <span class="headline-small-regular">
            {{ SINumberFormatter(metric.value) }}
          </span>
          <span
            class="title-medium-regular authors-card__footer__metric__label"
          >
            {{ metric.label }}
          </span>
        </Component>
      </div>
    </div>
  </RouterLink>
</template>

<script setup>
import { computed } from "vue";
import { SINumberFormatter } from "@/utils/helper.utils.js";
import { FOLLAWED_FEATURES } from "@/stores/user";
import StakeholderTag from "@/components/reusable/atoms/StakeholderTag.vue";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import ThemeTag from "@/components/reusable/atoms/ThemeTag.vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import PrmLink from "@/components/social-media/atoms/PrmLink.vue";
import BaseLink from "@/components/general/atoms/BaseLink.vue";
import SeparatorLine from "@/components/reusable/atoms/SeparatorLine.vue";
import {
  getLinkedinHandle,
  useHandle,
} from "@/composables/social-media/useHandle";
import { Platforms } from "@/utils/social360-platforms";
import { RouterLink } from "vue-router";

const props = defineProps({
  author: {
    type: Object,
    required: true,
  },
});

const socialHandles = [
  {
    platform: Platforms.TWITTER,
    icon: "fa-brands fa-lg fa-square-x-twitter",
    color: "var(--clr-twitter)",
    handle: props.author.x_information?.username,
  },
  {
    platform: Platforms.LINKEDIN,
    icon: "fa-brands fa-lg fa-linkedin",
    color: "var(--clr-linkedin)",
    handle: getLinkedinHandle(props.author),
  },
  {
    platform: Platforms.INSTAGRAM,
    icon: "fa-brands fa-lg fa-square-instagram",
    color: "var(--clr-instagram)",
    handle: props.author.instagram_information?.username,
  },
  {
    platform: Platforms.TIKTOK,
    icon: "fa-brands fa-tiktok",
    color: "var(--clr-tiktok)",
    handle: props.author.tiktok_information?.username,
  },
  {
    platform: Platforms.BLUESKY,
    icon: "fa-brands fa-lg fa-bluesky",
    color: "var(--clr-bluesky)",
    handle: props.author.bluesky_information?.username,
  },
].map((social) => {
  const handleData = useHandle(social.handle, social.platform);
  return {
    ...handleData,
    icon: social.icon,
    color: social.color,
  };
});

const metrics = [
  {
    label: "Abonné(e)s",
    value:
      // Support for legacy values
      props.author.x_information?.followers_tot ||
      props.author.x_information?.followers_total ||
      0,
  },
  { label: "Impressions", value: props.author.number_of_impressions },
  {
    label: "Mentions",
    value: props.author.number_of_mentions,
    routeUrl: `/stakeholders/${props.author._id}/mentions`,
  },
  {
    label: "Publications",
    value: props.author.number_of_publications,
    routeUrl: `/stakeholders/${props.author._id}/publications`,
  },
];

const linkUrl = computed(() => `/stakeholders/${props.author._id}/preview`);
</script>

<style lang="scss" scoped>
.authors-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--clr-n8);
  height: 100%;
  gap: 8px;

  &__wrapper {
    display: flex;
    gap: 8px;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &__tag {
      display: flex;
      align-items: start;
      gap: 4px;
    }
  }

  &__main {
    display: flex;
    gap: 4px;

    &__picture {
      min-width: 70px;
    }

    &__infos {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 16px;

      &__header {
        display: flex;
        align-items: center;
        gap: 8px;

        .name {
          text-transform: capitalize;
        }
      }

      &__handle {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      &__handles {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      }

      &__description {
        margin: 0;
      }
    }
  }

  &__footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    text-align: center;

    &__metric {
      display: flex;
      flex-direction: column;

      &.clickable:hover {
        background-color: var(--clr-n2);
      }

      &__number {
        font-size: 1.6875rem;
      }

      &__label {
        color: var(--clr-s7);
        letter-spacing: 0.3px;
      }
    }
  }
}
</style>
