<template>
  <div class="no-data">
    <div class="img">
      <slot name="image"></slot>
    </div>
    <p class="title-large-semibold">
      <slot name="title"></slot>
    </p>
    <p class="typo-body-regular">
      <slot name="message" />
    </p>
  </div>
</template>

<style scoped lang="scss">
p {
  margin: 0;
  text-align: center;
}

.no-data {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 12px;
  width: 100%;
  height: 100%;
  color: var(--clr-n8);

  .img {
    width: 30%;
  }
}
</style>
