<template>
  <img
    v-if="isBasic"
    v-bind="$attrs"
    src="@/assets/img/star-essential.svg"
    alt="Étoile argentée"
  />
  <img
    v-if="isSocietal"
    v-bind="$attrs"
    src="@/assets/img/star-societal.svg"
    alt="Étoile dorée"
  />
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  permissionLevel: { type: String, required: true },
});

const isBasic = computed(
  () =>
    props.permissionLevel === "BASIC" || props.permissionLevel === "TEAMS_BASIC"
);

const isSocietal = computed(
  () =>
    props.permissionLevel === "SOCIETAL" ||
    props.permissionLevel === "TEAMS_SOCIETAL"
);
</script>
