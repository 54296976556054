import { createRouter, createWebHistory } from "vue-router";
import { useToast } from "vue-toastification";

import { useAuthStore } from "@/stores/auth";
import side_menu from "@/utils/side_menu";

const toast = useToast();
const routes = [
  // Redirect legacy /login path to home
  // Note that the login form is displayed as soon as the user is not authenticated
  { path: "/login", redirect: "/" },
  {
    path: "/register",
    name: "Création de compte",
    component: () => import("@/views/RegisterView.vue"),
  },
  {
    path: "/forbidden",
    name: "403",
    component: () => import("@/views/ForbiddenView.vue"),
  },
  {
    path: "/filters-presets",
    name: "Préselections de filtres",
    component: () => import("@/views/top_menu_views/FiltersPresetsView.vue"),
  },
  {
    path: "/alerting",
    name: "Alerting",
    component: () => import("@/views/top_menu_views/AlertingView.vue"),
    children: [
      {
        path: "alerts",
        name: "Alerting - Alertes",
        component: () =>
          import("@/views/top_menu_views/alerting/AlertsTemplate.vue"),
      },
      {
        path: "digests",
        name: "Alerting - Récapitulatifs",
        component: () =>
          import("@/views/top_menu_views/alerting/DigestsTemplate.vue"),
      },
    ],
  },
  {
    name: "Paramètres utilisateur",
    path: "/user-settings",
    component: () =>
      import("@/views/top_menu_views/user_settings/UserSettingsView.vue"),
    children: [
      {
        name: "Paramètres utilisateur - Compte",
        path: "account",
        component: () =>
          import("@/components/user-settings/molecules/UserProfileForm.vue"),
      },
      {
        name: "Paramètres utilisateur - Abonnement",
        path: "subscription",
        component: () =>
          import("@/components/user-settings/molecules/SubscriptionForm.vue"),
      },
      {
        name: "Paramètres utilisateur - Notifications",
        path: "notifications",
        component: () =>
          import("@/components/user-settings/molecules/AlertSettings.vue"),
      },
    ],
  },
];

/**
  routes pattern :
  - path name in english (/name)
  - view component in english (nameView.vue)
*/

//* settings routes from side menu
side_menu.forEach((tab) => {
  if (tab.path) {
    //* primary tabs routes :
    routes.push({ ...tab });
  } else if (tab.secondary_tabs) {
    //* secondary tabs routes :
    tab.secondary_tabs.forEach((tab2) => {
      if (tab2.path) {
        routes.push({ ...tab2 });
      }

      if (tab2.secondary_routes) {
        //* tabs additional routes :
        tab2.secondary_routes.forEach((tab3) => {
          routes.push({ ...tab3 });
        });
      }
    });
  }
  if (tab.secondary_routes) {
    //* tabs additional routes :
    tab.secondary_routes.forEach((tab2) => {
      routes.push({ ...tab2 });
    });
  }
  if (tab.locked_route) {
    //* locked route for categories with restricted access :
    routes.push({ ...tab.locked_route });
  }
});

routes.push({
  path: "/:pathMatch(.*)*",
  name: "NotFound",
  component: () => import("@/views/ErrorView.vue"),
});

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // Use the saved position if available (for back/forward navigation)
      return savedPosition;
    } else if (to.hash) {
      // Scroll to the hash if it exists
      const element = document.querySelector(to.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return false;
    } else {
      // Scroll to the top of the page
      return { top: 0 };
    }
  },
});

const PERSISTENT_QUERY_PARAMS = [
  "dashboard",
  "search",
  "thematics",
  "subthematics",
];

const hasQueryParams = (route) => {
  return !!Object.keys(route.query).length;
};

router.beforeEach(async (to, from) => {
  let hasChanged = false;

  // Consume auto-login query parameters
  // if the parameters are present, login automatically
  if (to.query.auto_login_email && to.query.auto_login_code) {
    const authStore = useAuthStore();
    await authStore.createTokenWithDigicode({
      user_email: to.query.auto_login_email,
      code: to.query.auto_login_code,
    });

    // Remove the auto-login query params
    delete to.query.auto_login_email;
    delete to.query.auto_login_code;
    hasChanged = true;
  }

  // Consume Stripe status parameters
  // if the parameters are present, show the toast notification
  if (to.query["stripe-result"]) {
    if (to.query["stripe-result"] === "SUCCESS") {
      toast.success("Votre abonnement a été mis à jour avec succès !");
    } else if (to.query["stripe-result"] === "CANCEL") {
      toast.warning("Votre abonnement n'a pas été mis à jour.");
    }

    delete to.query["stripe-result"];
    hasChanged = true;
  }

  // Keep the persistent query params on page change
  // Even if they are not explicitly listed
  if (
    !hasQueryParams(to) &&
    hasQueryParams(from) &&
    useAuthStore().hasValidToken
  ) {
    const filteredQueryParams = Object.keys(from.query)
      .filter((key) => PERSISTENT_QUERY_PARAMS.includes(key))
      .reduce((obj, key) => {
        return { ...obj, [key]: from.query[key] };
      }, {});
    to.query = { ...to.query, ...filteredQueryParams };
    hasChanged = true;
  }

  if (hasChanged) {
    return to;
  }
});

export default router;
