<template>
  <div class="pagination">
    <!-- Previous Page -->
    <button
      :disabled="props.currentPage === 0"
      class="rounded-circle"
      aria-label="Page précédente"
      @click="changePage(props.currentPage - 1)"
    >
      <i class="fa-regular fa-chevron-left"></i>
    </button>

    <!-- First Page -->
    <button
      :class="{ active: props.currentPage == 0 }"
      class="rounded-circle"
      @click="changePage(0)"
    >
      1
    </button>

    <div v-if="showEllipsisBeforeCurrentPage" class="rounded-circle">...</div>

    <!-- Middle Pages -->
    <div v-for="p in pagesBetweenEllipsis" :key="p">
      <button
        :class="{ active: props.currentPage == p }"
        class="rounded-circle"
        @click="changePage(p)"
      >
        {{ p + 1 }}
      </button>
    </div>

    <!-- last page -->
    <div v-if="showEllipsisAfterCurrentPage" class="rounded-circle">...</div>

    <button
      v-if="totalPages > 1"
      :class="{ active: props.currentPage == totalPages - 1 }"
      class="rounded-circle"
      @click="changePage(totalPages - 1)"
    >
      {{ totalPages }}
    </button>

    <!-- Next Page -->
    <button
      :disabled="props.currentPage === totalPages - 1"
      class="rounded-circle"
      aria-label="Page suivante"
      @click="changePage(props.currentPage + 1)"
    >
      <i class="fa-regular fa-chevron-right"></i>
    </button>

    <!-- go to page -->
    <div v-if="showPageSelectorInput" class="goToPage">
      <label for="goTo">Aller à la page</label>
      <input
        id="goTo"
        v-model="pageSelectorInput"
        type="number"
        min="1"
        :max="totalPages"
        @keypress="validateNumber($event)"
        @keydown.enter="selectPage($event)"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  totalItems: {
    type: Number,
    required: true,
  },
  itemsLimit: {
    type: Number,
    required: true,
  },
  // currentPage is the internal representation, which is 0-indexed
  // Page number displayed to the user is always 1-indexed
  currentPage: {
    type: Number,
    required: true,
  },
});
const emit = defineEmits(["changeCurrentPage"]);

const pageSelectorInput = ref(null);

// When there are too many pages, we display ellipsis around the current page
const nbPagesBetweenEllipsis = 4;
const totalPages = computed(() =>
  Math.ceil(props.totalItems / props.itemsLimit)
);

// * pagination condition
// ensure current page isn't out of range
if (props.currentPage > totalPages.value) {
  emit("changeCurrentPage", 0);
}

const showEllipsisBeforeCurrentPage = computed(
  () => props.currentPage >= nbPagesBetweenEllipsis
);
const showEllipsisAfterCurrentPage = computed(
  () => props.currentPage < totalPages.value - nbPagesBetweenEllipsis
);

const showPageSelectorInput = computed(
  () => totalPages.value >= nbPagesBetweenEllipsis + 2
);

const pagesBetweenEllipsis = computed(() => {
  const deltaPage = Math.floor(nbPagesBetweenEllipsis / 2);

  const firstPageBetweenEllipsis = Math.max(1, props.currentPage - deltaPage);

  const lastPageBetweenEllipsis = Math.min(
    totalPages.value - 2,
    props.currentPage + deltaPage
  );

  const pages = [];
  for (let _p = firstPageBetweenEllipsis; _p <= lastPageBetweenEllipsis; _p++) {
    pages.push(_p);
  }
  return pages;
});

function changePage(to) {
  if (to >= 0 && Number.isInteger(to) && to < totalPages.value) {
    emit("changeCurrentPage", to);
    // Reset page input selector
    pageSelectorInput.value = null;
  }
}

function selectPage() {
  changePage(pageSelectorInput.value - 1);
}

function validateNumber(evt) {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;

  .rounded-circle {
    border-radius: 50rem;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 23px;
    height: 23px;

    &:not([disabled]) {
      &:hover,
      &:focus {
        background-color: var(--clr-p1);
        box-shadow: var(--shd-button-hover);
      }
      &:active {
        background-color: var(--clr-p2);
        box-shadow: var(--shd-button-hover) inset;
      }
    }

    &.active {
      padding: 0 0.3em;
      background: var(--clr-p5);
      color: white;

      &:not([disabled]) {
        &:hover,
        &:focus {
          background-color: var(--clr-p4);
        }
        &:active {
          background-color: var(--clr-p5);
        }
      }
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  .goToPage {
    display: flex;
    align-items: center;

    label {
      margin-left: 5px;
      cursor: pointer;
    }

    input[type="number"] {
      margin-left: 7px;
      border: 1px solid var(--clr-p5);
      border-radius: 3px;
      width: 35px;
      height: 25px;
      padding: 0;
      text-align: center;

      /* Remove default arrow */
      appearance: textfield;
      -moz-appearance: textfield;

      /* Firefox */
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
</style>
