<template>
  <div
    class="carousel"
    @mousedown="startDrag"
    @mousemove="onDrag"
    @mouseup="endDrag"
    @mouseleave="endDrag"
    @touchstart="startDrag"
    @touchmove="onDrag"
    @touchend="endDrag"
    @wheel="onWheel"
  >
    <div
      class="carousel__track"
      :style="trackStyle"
      @transitionend="handleTransitionEnd"
    >
      <div
        v-for="(slide, index) in clonedSlides"
        :key="index"
        class="carousel__item"
      >
        <slot :slide="slide"></slot>
      </div>
    </div>

    <template v-if="totalSlides > 1">
      <button
        class="carousel__control carousel__control--left"
        aria-label="Slide précédente"
        @click="prevSlide"
      >
        <i class="fa-regular fa-chevron-left" />
      </button>
      <button
        class="carousel__control carousel__control--right"
        aria-label="Slide suivante"
        @click="nextSlide"
      >
        <i class="fa-regular fa-chevron-right" />
      </button>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onUnmounted } from "vue";

const props = defineProps({
  slides: {
    type: Array,
    required: true,
  },
});

const currentSlide = ref(1);
const isDragging = ref(false);
const startPosition = ref(0);
const dragOffset = ref(0);
const transitionEnabled = ref(true);
let transitionTimeout = null;
let animationFrameId = null;

const clonedSlides = computed(() => {
  return [
    props.slides[props.slides.length - 1],
    ...props.slides,
    props.slides[0],
  ];
});

const totalSlides = computed(() => props.slides.length);

const trackStyle = computed(() => {
  return {
    transform: `translateX(calc(-${currentSlide.value * 100}% + ${dragOffset.value}px))`,
    transition: transitionEnabled.value ? "transform 0.3s ease" : "none",
  };
});

const resetTransition = () => {
  if (transitionTimeout) {
    clearTimeout(transitionTimeout);
  }
  transitionEnabled.value = true;
};
const prevSlide = () => {
  if (transitionTimeout) clearTimeout(transitionTimeout);
  transitionEnabled.value = true;

  if (currentSlide.value === 1) {
    currentSlide.value--;
    setTimeout(() => {
      transitionEnabled.value = false;
      currentSlide.value = totalSlides.value;
    }, 300);
  } else {
    currentSlide.value--;
  }
};

const nextSlide = () => {
  if (transitionTimeout) clearTimeout(transitionTimeout);
  transitionEnabled.value = true;

  if (currentSlide.value === totalSlides.value) {
    currentSlide.value++;
    setTimeout(() => {
      transitionEnabled.value = false;
      currentSlide.value = 1;
    }, 300);
  } else {
    currentSlide.value++;
  }
};

const startDrag = (event) => {
  isDragging.value = true;
  transitionEnabled.value = false;
  startPosition.value = event.type.includes("mouse")
    ? event.pageX
    : event.touches[0].clientX;
};

const onDrag = (event) => {
  if (!isDragging.value) return;

  const currentPosition = event.type.includes("mouse")
    ? event.pageX
    : event.touches[0].clientX;

  const offset = currentPosition - startPosition.value;

  if (animationFrameId) {
    cancelAnimationFrame(animationFrameId);
  }

  animationFrameId = requestAnimationFrame(() => {
    dragOffset.value = offset;
  });
};

const endDrag = () => {
  if (!isDragging.value) return;

  isDragging.value = false;
  transitionEnabled.value = true;

  if (animationFrameId) {
    cancelAnimationFrame(animationFrameId);
    animationFrameId = null;
  }

  if (dragOffset.value < -25) {
    nextSlide();
  } else if (dragOffset.value > 25) {
    prevSlide();
  }

  dragOffset.value = 0;
};

const isWheelScrolling = ref(false);

const onWheel = (event) => {
  event.preventDefault();

  if (isDragging.value || isWheelScrolling.value) return;

  const roundedDeltaY = Math.round(event.deltaY);
  const sensitivityThreshold = 1;

  if (Math.abs(roundedDeltaY) >= sensitivityThreshold) {
    isWheelScrolling.value = true;

    if (roundedDeltaY > 0) {
      nextSlide();
    } else if (roundedDeltaY < 0) {
      prevSlide();
    }

    setTimeout(() => {
      isWheelScrolling.value = false;
    }, 300);
  }
};

const handleTransitionEnd = () => {
  if (currentSlide.value === totalSlides.value + 1) {
    currentSlide.value = 1;
    transitionEnabled.value = false;
    transitionTimeout = setTimeout(() => {
      resetTransition();
    }, 0);
  }

  if (currentSlide.value === 0) {
    currentSlide.value = totalSlides.value;
  }
};

onUnmounted(() => {
  if (transitionTimeout) clearTimeout(transitionTimeout);
});
</script>

<style scoped lang="scss">
.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;

  &__track {
    display: flex;
    transition: transform 0.5s ease;
  }

  &__item {
    min-width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    user-select: none;
  }

  &__control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }
}
</style>
