<template>
  <div class="template">
    <div class="englobe-params">
      <RouterLink class="back" to="/reports"
        ><i class="fa-solid fa-chevron-left"></i> Retour à la liste</RouterLink
      >
    </div>

    <LoaderFullPage v-if="store.loading" />
    <div v-else-if="store.reportCompteRendu" class="card">
      <div class="header">
        <div>{{ getDayTimeFormat(store.reportCompteRendu.created_at) }}</div>
        <SourceLink
          v-if="store.reportCompteRendu.url"
          :href="store.reportCompteRendu.url"
          label="Source"
        />
      </div>
      <div class="title-row">
        <InstitutionBadge :institution="store.reportCompteRendu.institution" />
        <div class="title-col">
          <div class="title">{{ store.reportCompteRendu.title }}</div>
          <div class="report-type">{{ store.reportCompteRendu.type_ }}</div>
        </div>
      </div>
      <div class="body">
        <OccurrencesCollapse
          v-if="hasOccurrences"
          :occurrences="store.reportCompteRendu.occurrences"
        />
        <FloatingBrowseOccurrences v-if="hasOccurrences" />

        <div
          v-if="store.reportCompteRendu.personalities.length > 0"
          class="card"
        >
          <ListOfDeputies
            :deputies-list="store.reportCompteRendu.personalities"
            title="Participant(e)s"
          />
        </div>

        <!-- eslint-disable vue/no-v-html -->
        <div
          class="report-content typo-body-regular"
          v-html="store.reportCompteRendu.content"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
    <ErrorPage
      v-else
      :error="{ message: 'Impossible de charger ce rapport' }"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useLegiReportStore } from "@/stores/legi-reports";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import { getDayTimeFormat } from "@/utils/formatDate";
import InstitutionBadge from "@/components/legi-work/atoms/InstitutionBadge.vue";
import OccurrencesCollapse from "@/components/reusable/atoms/OccurrencesCollapse.vue";
import ListOfDeputies from "@/components/reusable/molecules/ListOfDeputies.vue";
import FloatingBrowseOccurrences from "@/components/reusable/atoms/FloatingBrowseOccurrences.vue";
import ErrorPage from "@/components/reusable/atoms/ErrorPage.vue";

const store = useLegiReportStore();
const route = useRoute();

const hasOccurrences = computed(
  () => Object.keys(store.reportCompteRendu.occurrences || {}).length > 0
);

store.fetchOneCompteRendu(route.params.docRef);
</script>

<style scoped lang="scss">
.header {
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.title-row {
  display: flex;
  gap: 12px;

  color: var(--clr-n8);

  margin-bottom: 12px;

  .title-col {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 700;
    }

    .report-type {
      text-transform: capitalize;
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.report-content {
  color: var(--clr-n8);

  :deep(.personality) {
    padding-top: 12px;
    font-weight: 600;
  }
}
</style>
